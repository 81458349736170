/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { User } from '@/client';

export type GetTenantListQueryProps = {
  currentUser?: User;
};

export function useGetTenantListQuery({ currentUser }: GetTenantListQueryProps) {
  return useQuery({
    queryKey: ['tenants'],
    queryFn: () => mgmtPlaneAPIClient.tenants.getTenants({ page: 1, pageSize: 100 }),
    staleTime: 5 * 1000,
    enabled: currentUser?.role === 'superadmin',
  });
}
