/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Button } from '@/primitives/Button';
import { Flex } from '@/primitives/Flex';
import { Text } from '@/primitives/Text';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import NoData from '../Icons/NoData.svg?react';
import { NodeViewPageCard } from '../Node/NodeViewPageCard';

export type AssignConfigProps = {
  assignNewNodeConfig: () => void;
};

export function AssignConfig({ assignNewNodeConfig }: AssignConfigProps) {
  const { t } = useMimicTranslation('node');

  return (
    <NodeViewPageCard dataTestId="assignConfig" bodyStyles={{ padding: '0 0 16px 0' }}>
      <div style={{ padding: '40px 0' }}>
        <Flex vertical align="center">
          <NoData fontSize="6em" />
          <Text type="subtle">{t('notConfigured')}</Text>
          <Button
            type="primary"
            onClick={() => assignNewNodeConfig()}
            dataTestId="assign-config-button"
            style={{ minHeight: '48px' }}
          >
            {t('assignConfiguration')}
          </Button>
        </Flex>
      </div>
    </NodeViewPageCard>
  );
}
