/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { queryOptions } from '@tanstack/react-query';
import { mgmtPlaneAPIClient, PaginationParams } from '@/client/mgmtPlane';
import { TagFilters, TagSortableFields } from '@/client';

export function getTagListOptions(
  tenantId: string,
  filters: TagFilters,
  sort: TagSortableFields,
  settings: PaginationParams,
) {
  return queryOptions({
    queryKey: ['tags', tenantId, settings.number, settings.size, sort, filters.name] as (string | number | undefined)[],
    queryFn: () =>
      mgmtPlaneAPIClient.tags.getTags({ tenantId, page: settings.number, pageSize: settings.size, filters, sort }),
    enabled: Boolean(tenantId),
  });
}

export type GetTagListOptionsFn = typeof getTagListOptions;
export type GetTagListOptions = ReturnType<typeof getTagListOptions>;
