/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ArrowLeftOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Button } from '@/primitives/Button';
import './ConfigureNodesJobWizardFooter.css';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { tokens } from '@/theme';
import { Text } from '@/primitives/Text';

export type ConfigureNodesJobWizardFooterProps = {
  onNext: () => void;
  onPrevious?: () => void;
  onNextLabel: string;
  onNextIcon?: React.ReactNode;
  error?: boolean;
  errorMessage?: string;
  loading?: boolean;
};

export function ConfigureNodesJobWizardFooter({
  onNext,
  onPrevious,
  onNextLabel,
  onNextIcon,
  error,
  errorMessage,
  loading,
}: ConfigureNodesJobWizardFooterProps) {
  const leftButton = onPrevious ? <PreviousButton onPrevious={onPrevious} /> : null;

  return (
    <div className="job-wizard-overlay-footer" style={error ? { backgroundColor: 'rgba(248, 233, 229, 0.7)' } : {}}>
      <div className="left-buttons">{leftButton}</div>
      <div style={{ display: 'flex', gap: '24px' }}>
        <ErrorMessage error={error} errorMessage={errorMessage} />
        <Button dataTestId="next-job-wizard-overlay" type="primary" onClick={onNext} loading={loading}>
          {onNextLabel}
          {onNextIcon || null}
        </Button>
      </div>
    </div>
  );
}

function PreviousButton({ onPrevious }: Required<Pick<ConfigureNodesJobWizardFooterProps, 'onPrevious'>>) {
  const { t } = useMimicTranslation('configureNodesJobWizard');
  return (
    <Button
      icon={<ArrowLeftOutlined />}
      dataTestId="previous-config-overlay"
      type="default"
      onClick={() => onPrevious()}
    >
      {t('previous')}
    </Button>
  );
}

function ErrorMessage({ error, errorMessage }: Pick<ConfigureNodesJobWizardFooterProps, 'errorMessage' | 'error'>) {
  if (!error || !errorMessage) return null;

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <ExclamationCircleFilled
        style={{ color: tokens.color.error.error, fontSize: '1.5rem', marginRight: '4px' }}
        data-testid="bang-icon"
      />
      <Text type="error">{errorMessage}</Text>
    </div>
  );
}
