/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { UseMutationOptions } from 'node_modules/@tanstack/react-query/build/legacy';
import { ApiError } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type DeleteTag = {
  id: string;
};

export type DeleteTagMutationConfig = {
  onSuccess?: (data: void, variables: DeleteTag) => void;
  onError?: (error: ApiError, variables: DeleteTag) => void;
  onSettled?: (data: void, error: ApiError | null, variables: DeleteTag) => void;
};

export function getDeleteTagMutationOptions(
  tenantId: string,
  queryClient: QueryClient,
  config?: DeleteTagMutationConfig,
) {
  return {
    mutationFn: (deleteTag: DeleteTag) => {
      return mgmtPlaneAPIClient.tags.deleteTag({ tenantId, id: deleteTag.id });
    },
    onSuccess: async (data, variables) => {
      if (config?.onSuccess) {
        await config.onSuccess(data, variables);
      }
      queryClient.invalidateQueries({ queryKey: ['tags'] });
    },
    onError: config?.onError,
    onSettled: config?.onSettled,
  } satisfies UseMutationOptions<void, ApiError, DeleteTag>;
}

export function useDeleteTagMutation(tenantID: string, config?: DeleteTagMutationConfig) {
  const queryClient = useQueryClient();
  return useMutation<void, ApiError, DeleteTag>(getDeleteTagMutationOptions(tenantID, queryClient, config));
}
