/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Input, InputRef } from 'antd';
import { ColumnType } from 'antd/es/table';
import { RefObject, useState } from 'react';
import { Space } from '@/primitives/Space';
import { Button } from '@/primitives/Button';

type FilterDropdownProps<Resource> = {
  value: string | undefined;
  dataIndex: ColumnType<Resource>['dataIndex'];
  searchInput: RefObject<InputRef>;
  handleClose: () => void;
  handleSearch: (value?: string) => void;
};

export function FilterDropdown<Resource>({
  value,
  handleClose,
  searchInput,
  dataIndex,
  handleSearch,
}: FilterDropdownProps<Resource>) {
  const [fieldValue, setFieldValue] = useState<string>(value || '');
  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        value={fieldValue}
        data-testid={`filter-dropdown-input-${dataIndex}`}
        onChange={(e) => setFieldValue(e.target.value)}
        placeholder={`Search ${dataIndex}`}
        onKeyDown={(e) => e.stopPropagation()}
        onPressEnter={() => handleSearch(fieldValue)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          onClick={() => {
            setFieldValue('');
            handleSearch(undefined);
          }}
          onKeyDown={(e) => e.stopPropagation()}
          type="default"
          style={{ width: 90 }}
        >
          Reset
        </Button>

        <Button type="link" onClick={handleClose}>
          close
        </Button>
      </Space>
    </div>
  );
}
