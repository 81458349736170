/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';
import { JobNodeFilters, JobNodeSortableFields } from '@/client';
import { mgmtPlaneAPIClient, PaginationParams } from '@/client/mgmtPlane';

export type JobNodesQueryParams = {
  jobId?: string;
  tenantId?: string;
  filters: JobNodeFilters;
  sort: JobNodeSortableFields;
  settings: PaginationParams;
};

export function useJobNodesQuery({ jobId, tenantId, filters, sort, settings }: JobNodesQueryParams) {
  return useQuery({
    queryKey: ['job-nodes', jobId, settings.number, settings.size, sort, filters],
    queryFn: async () => {
      return mgmtPlaneAPIClient.jobs.getJobNodes({
        jobId: jobId!,
        tenantId: tenantId!,
        page: settings.number,
        pageSize: settings.size,
        filters,
        sort,
      });
    },
    staleTime: 5 * 1000,
    enabled: !!tenantId,
  });
}
