/*
 * Copyright Mimic Networks, Inc. 2024.
 */

const second = 1000;
const minute = 60 * second;
const hour = minute * 60;
const day = hour * 24;
const month = day * 30;
const year = day * 365;

export const TIME_UNIT = {
  second,
  minute,
  hour,
  day,
  month,
  year,
};

type TimeWindow = {
  unit: number;
  singular: string;
  plural: string;
};

const TimeWindows: TimeWindow[] = [
  {
    unit: second,
    singular: 'second',
    plural: 'seconds',
  },
  {
    unit: minute,
    singular: 'minute',
    plural: 'minutes',
  },
  {
    unit: hour,
    singular: 'hour',
    plural: 'hours',
  },
  {
    unit: day,
    singular: 'day',
    plural: 'days',
  },
  {
    unit: month,
    singular: 'month',
    plural: 'months',
  },
  {
    unit: year,
    singular: 'year',
    plural: 'years',
  },
].reverse();

// Default to seconds. This can happen in rare cases with negative times
// (ie server off from local clock) and less than 1 sec difference.
const DefaultTimeWindow = TimeWindows[TimeWindows.length - 1];

export function timeAgo(date: Date) {
  let diff = Number(new Date()) - Number(date);
  diff = diff < 0 ? 0 : diff;

  if (diff < minute) return 'Less than a minute ago';

  const timeWindow = TimeWindows.find((t) => t.unit < diff) || DefaultTimeWindow;
  const n = Math.round(diff / timeWindow.unit);
  const suffix = n > 1 ? timeWindow.plural : timeWindow.singular;
  return `${Math.round(diff / timeWindow.unit)} ${suffix} ago`;
}
