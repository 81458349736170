/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateUser, UserResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type NewUserMutationConfig = {
  onSuccess?: (data: UserResponse, variables: CreateUser) => void;
  onError?: (error: unknown, variables: CreateUser) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
};

export function useNewUserMutation(tenantId: string, config?: NewUserMutationConfig) {
  const queryClient = useQueryClient();

  return useMutation<UserResponse, unknown, CreateUser>({
    mutationFn: (newUser: CreateUser) => {
      return mgmtPlaneAPIClient.users.createUser({ tenantId, requestBody: newUser });
    },
    onSuccess: (data, variables) => {
      if (config?.onSuccess) {
        config.onSuccess(data, variables);
      }
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
    onError: config?.onError,
    onSettled: config?.onSettled,
  });
}
