/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import {
  CodeOutlined,
  DeploymentUnitOutlined,
  OneToOneOutlined,
  BookOutlined,
  BuildOutlined,
  ApiOutlined,
  BellOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Menu } from '@/primitives/Menu';
import { MenuItemConfig, MenuProps, buildMenu } from './builder';
import './MainMenu.css';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';

export function MainMenu({ tenantID, currentUser, navigate, currentPath }: MenuProps) {
  const { t } = useMimicTranslation('menu');

  const isSuperAdmin = currentUser?.role === 'superadmin';

  let menu: MenuItemConfig[] = [
    {
      name: 'activity',
      icon: <OneToOneOutlined />,
      label: t('activity'),
      path: `/tenants/${tenantID}/activity`,
      dataTestId: 'activity-menu',
    },
    {
      name: 'nodes',
      icon: <DeploymentUnitOutlined />,
      label: t('nodes'),
      path: `/tenants/${tenantID}/nodes`,
      dataTestId: 'nodes-menu',
    },
    {
      name: 'configurations',
      icon: <BuildOutlined />,
      label: t('configurations'),
      path: `/tenants/${tenantID}/node-configs`,
      dataTestId: 'configurations-menu',
    },
    {
      name: 'jobs',
      icon: <UnorderedListOutlined />,
      label: t('jobs'),
      path: `/tenants/${tenantID}/jobs`,
      dataTestId: 'jobs-menu',
    },
    {
      name: 'notifications',
      icon: <BellOutlined />,
      label: t('notifications'),
      path: `/tenants/${tenantID}/notifications`,
      dataTestId: 'notifications-menu',
    },
    {
      name: 'mimicCLI',
      icon: <CodeOutlined />,
      label: t('mimicCli'),
      dataTestId: 'mimic-cli-menu',
      items: [
        {
          name: 'download',
          label: t('download'),
          dataTestId: 'mimic-cli-download-menu',
          path: `/tenants/${tenantID}/mimic-cli/download`,
        },
        {
          name: 'authenticate',
          label: t('authenticate'),
          dataTestId: 'mimic-cli-authenticate-menu',
          path: `/tenants/${tenantID}/mimic-cli/authenticate`,
        },
      ],
    },
  ];

  menu = isSuperAdmin
    ? [
        ...menu,
        {
          name: 'docs',
          icon: <BookOutlined />,
          label: t('docs'),
          items: [
            {
              name: 'swagger',
              label: t('swagger'),
              icon: <ApiOutlined />,
              path: `/tenants/${tenantID}/docs/swagger`,
            },
          ],
        },
      ]
    : menu;

  const { items, defaultSelectedKeys, defaultOpenKeys } = buildMenu(menu, currentPath, navigate);
  return (
    <Menu items={items} key={currentPath} defaultSelectedKeys={defaultSelectedKeys} defaultOpenKeys={defaultOpenKeys} />
  );
}
