/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ErrorResponse } from '@/client';
import { ApiRequestOptions } from '@/client/core/ApiRequestOptions';
import { ApiResult } from '@/client/core/ApiResult';

export class MimicApiError extends Error {
  public readonly url: string;

  public readonly status: number;

  public readonly statusText: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public readonly body: any;

  public readonly request: ApiRequestOptions;

  constructor(request: ApiRequestOptions, response: ApiResult, message: string) {
    super(message);

    this.name = 'ApiError';
    this.url = response.url;
    this.status = response.status;
    this.statusText = response.statusText;
    this.body = response.body;
    this.request = request;
  }
}

export function getValidationMessage(e: MimicApiError, field: string) {
  if (e.body && e.body.errors && e.body.errors.length > 0) {
    const errorResponse = e.body as ErrorResponse;
    const fieldError = errorResponse.errors?.find((error) => error.field === field);
    if (fieldError) {
      return fieldError.message;
    }
  }
  return e.body.message;
}

export function isValidationErrorStatus(status: number) {
  return status === 400 || status === 422;
}
