/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { InfoCircleOutlined, LogoutOutlined, TagsOutlined, UserOutlined } from '@ant-design/icons';
import { MenuItemConfig, MenuProps, buildMenu } from './builder';
import { Menu } from '@/primitives/Menu';
import { selectedTenantIDKey, useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { AvatarAndName } from '../AvatarAndName';

export function AccountMenu({ tenantID, navigate, currentPath }: MenuProps) {
  const { t } = useMimicTranslation('menu');
  const profile = useMgmtPlaneStateStore((state) => state.userProfile);
  const menu: MenuItemConfig[] = [
    {
      name: 'accountMenu',
      label: <AvatarAndName userInfo={profile} />,
      dataTestId: 'account-menu',

      items: [
        {
          name: 'members',
          icon: <UserOutlined />,
          label: t('members'),
          path: `/tenants/${tenantID}/members`,
          dataTestId: 'members-menu',
        },
        {
          name: 'tags',
          icon: <TagsOutlined />,
          label: t('tags'),
          path: `/tenants/${tenantID}/tags`,
          dataTestId: 'tags-menu',
        },
        {
          name: 'logout',
          icon: <LogoutOutlined />,
          label: t('logout'),
          onClick: () => {
            localStorage.removeItem(selectedTenantIDKey);
            window.location.href = `${window.mimicConfig.MIMIC_MGMT_PLANE_API}/api/auth/sign-out`;
          },
          danger: true,
          dataTestId: 'logout-menu',
        },
        {
          type: 'divider',
        } as unknown as MenuItemConfig,
        {
          name: 'system-info',
          icon: <InfoCircleOutlined />,
          label: t('systemInfo'),
          path: '/system-info',
          dataTestId: 'system-info',
        },
      ],
    },
  ];

  const { items, defaultSelectedKeys } = buildMenu(menu, currentPath, navigate);
  return <Menu key={currentPath} mode="vertical" items={items} defaultSelectedKeys={defaultSelectedKeys} />;
}
