/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Layout as AntDLayout } from 'antd';

const { Footer: AntDFooter } = AntDLayout;

export type FooterProps = {
  children: React.ReactNode;
};

export function Footer({ children }: FooterProps) {
  return <AntDFooter style={{ position: 'sticky', top: '100vh' }}>{children}</AntDFooter>;
}
