/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NodeConfigResponse, PatchNodeConfig } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type UpdateNodeConfigMutationConfig = {
  onSuccess?: (data: NodeConfigResponse, variables: PatchNodeConfig) => void;
  onError?: (error: unknown, variables: PatchNodeConfig) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
};

export function useUpdateNodeConfigMutation(
  tenantId: string,
  configId: string,
  config?: UpdateNodeConfigMutationConfig,
) {
  const queryClient = useQueryClient();

  return useMutation<NodeConfigResponse, unknown, PatchNodeConfig>({
    mutationFn: (updatedNodeConfig: PatchNodeConfig) => {
      return mgmtPlaneAPIClient.nodeConfigs.updateNodeConfig({
        tenantId,
        configId,
        requestBody: updatedNodeConfig,
      });
    },
    onSuccess: (data, variables) => {
      if (config?.onSuccess) {
        config.onSuccess(data, variables);
      }
      queryClient.invalidateQueries({ queryKey: ['node-config', configId] });
    },
    onError: config?.onError,
    onSettled: config?.onSettled,
  });
}
