/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form } from 'antd';
import { useMemo } from 'react';
import { Skeleton } from '@/primitives/Skeleton';
import { Text } from '@/primitives/Text';
import './IncomingData.css';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';

export type IncomingDataProps = {
  label: string;
  loading: boolean;
  text?: string | React.ReactNode;
  children?: React.ReactNode;
  copyable?: boolean;
  mono?: boolean;
  marginBottom?: number;
  labelMarginBottom?: string;
  disabled?: boolean;
  dataTestId?: string;
  bold?: boolean;
};

function IncomingDataContent({ text, children, loading, dataTestId, mono, copyable }: Partial<IncomingDataProps>) {
  const { t: tGlobal } = useMimicTranslation('global');
  const notAvailable = !text && !children && !loading;
  if (notAvailable) {
    return (
      <Text data-testid={dataTestId} type="subtle">
        {tGlobal('notAvailable')}
      </Text>
    );
  }

  return typeof text === 'string' ? (
    <Text data-testid={dataTestId} copyable={copyable} mono={mono}>
      {text}
    </Text>
  ) : (
    children
  );
}

export function IncomingData({
  label,
  loading,
  text,
  copyable,
  mono,
  children,
  marginBottom,
  labelMarginBottom,
  disabled,
  dataTestId,
  bold,
}: IncomingDataProps) {
  const formLabel = useMemo(() => {
    if (!bold) return label;
    return (
      <Text type="subtle" size="lg" strong>
        {label}
      </Text>
    );
  }, [bold, label]);

  const child = (
    <IncomingDataContent text={text} loading={loading} dataTestId={dataTestId} mono={mono} copyable={copyable}>
      {children}
    </IncomingDataContent>
  );

  return (
    <Form.Item
      className={disabled ? 'disabled-incoming-data' : 'incoming-data'}
      label={formLabel}
      style={{ marginBottom }}
    >
      <div style={{ marginTop: labelMarginBottom }}>
        <Skeleton loading={loading}>{child}</Skeleton>
      </div>
    </Form.Item>
  );
}
