/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  FieldTimeOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { tokens } from '@/theme';
import { JobStatus } from '@/client';
import { Flex } from '@/primitives/Flex';
import { Text } from '@/primitives/Text';
import { MimicTranslationFunction, useMimicTranslation } from '@/utils/translation/useMimicTranslation';

export type JobNodeStatusProps = {
  status: JobStatus;
};

const fontSize = '1.25rem';

function getJobNodeStatusIcon(status: JobStatus, t: MimicTranslationFunction<'jobs'>) {
  if (status === 'success') {
    return (
      <Flex align="center" gap="xs">
        <CheckCircleFilled style={{ fontSize, color: tokens.color.success.successDefault }} />
        <Text type="default" size="md">
          {t('jobNodes.configured')}
        </Text>
      </Flex>
    );
  }
  if (status === 'error') {
    return (
      <Flex align="center" gap="xs">
        <ExclamationCircleFilled style={{ fontSize, color: tokens.color.error.error }} />
        <Text type="default" size="md">
          {t('jobNodes.rejected')}
        </Text>
      </Flex>
    );
  }
  if (status === 'warning') {
    return (
      <Flex align="center" gap="xs">
        <WarningFilled style={{ fontSize, color: tokens.color.yellow.yellow06 }} />
        <Text type="default" size="md">
          {t('jobNodes.warning')}
        </Text>
      </Flex>
    );
  }
  if (status === 'running') {
    return (
      <Flex align="center" gap="xs">
        <LoadingOutlined />
        <Text type="default" size="md">
          {t('jobNodes.processing')}
        </Text>
      </Flex>
    );
  }
  if (status === 'timeout') {
    return <FieldTimeOutlined style={{ fontSize }} />;
  }

  return <QuestionCircleOutlined style={{ fontSize }} />;
}

export function JobNodeStatus({ status }: JobNodeStatusProps) {
  const { t } = useMimicTranslation('jobs');
  const statusIcon = getJobNodeStatusIcon(status, t);

  return (
    <Flex align="center" gap="xs">
      {statusIcon}
    </Flex>
  );
}
