/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form, FormInstance } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { PatchUser } from '@/client';
import { Input } from '@/primitives/Input';
import { GetUserOptions } from '@/utils/hooks/getUserOptions';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { ValidationError } from '../Forms/ValidationError';
import { Skeleton } from '@/primitives/Skeleton';

const requiredMarkAfter = (label: React.ReactNode) => label;

export type UpdateMemberFormParams = {
  form: FormInstance<PatchUser>;
  userOptions: GetUserOptions;
};

export function UpdateMemberForm({ form, userOptions }: UpdateMemberFormParams) {
  const { t } = useMimicTranslation('members');
  const { t: tGlobal } = useMimicTranslation('global');
  const { data: userResponse, isLoading } = useQuery(userOptions);

  if (isLoading) {
    return <Skeleton loading />;
  }
  return (
    <Form
      validateTrigger="onBlur"
      form={form}
      layout="vertical"
      requiredMark={requiredMarkAfter}
      data-testid="update-member-form"
    >
      <Form.Item
        name="firstName"
        label={t('firstName')}
        initialValue={userResponse?.data.firstName}
        rules={[
          { required: true, message: <ValidationError>{tGlobal('forms.required')}</ValidationError> },
          { type: 'string', message: <ValidationError>{tGlobal('forms.required')}</ValidationError> },
        ]}
      >
        <Input dataTestId="member-first-name" allowClear />
      </Form.Item>
      <Form.Item
        name="lastName"
        label={t('lastName')}
        initialValue={userResponse?.data.lastName}
        rules={[
          { required: true, message: <ValidationError>{tGlobal('forms.required')}</ValidationError> },
          { type: 'string', message: <ValidationError>{tGlobal('forms.required')}</ValidationError> },
        ]}
      >
        <Input dataTestId="member-last-name" allowClear />
      </Form.Item>
      <Form.Item
        name="email"
        label={t('email')}
        initialValue={userResponse?.data.email}
        rules={[
          { required: true, message: <ValidationError>{t('invite.enverValidEmail')}</ValidationError> },
          { min: 1, message: <ValidationError>{t('invite.enverValidEmail')}</ValidationError> },
          { type: 'email', message: <ValidationError>{t('invite.enverValidEmail')}</ValidationError> },
        ]}
      >
        <Input dataTestId="member-email" allowClear />
      </Form.Item>
    </Form>
  );
}
