/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { User } from '@/client';

export interface UserFormatted extends User {
  key: React.Key;
  roleFormatted: string;
  dateCreatedDate: Date;
}

export type Role = User['role'];

export enum ActionType {
  MAKE_ADMIN = 'makeAdmin',
  MAKE_EDITOR = 'makeEditor',
  MAKE_VIEWER = 'makeViewer',
  UPDATE = 'updateUser',
  REMOVE = 'remove',
}

export const RoleAction: Partial<Record<Role, ActionType>> = {
  admin: ActionType.MAKE_ADMIN,
  editor: ActionType.MAKE_EDITOR,
  viewer: ActionType.MAKE_VIEWER,
};

export function formatUserForTable(user: User, roleFormatted: string): UserFormatted {
  return {
    ...user,
    key: user.id,
    roleFormatted,
    dateCreatedDate: new Date(user.dateCreated),
  };
}
