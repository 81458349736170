/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form } from 'antd';
import { useState } from 'react';
import { Card } from '@/primitives/Card';
import { Button } from '@/primitives/Button';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { ApiError, Tag } from '@/client';
import { validationErrors } from '../Forms/validationErrors';
import { TagForm, TagFormFields } from './TagForm';
import { getValidationMessage } from '@/utils/errors';

export type NewTagDialogProps = {
  onAddedTag: (tag: Tag) => void;
  onTagCreate: (name: string) => Promise<Tag>;
};

export function NewTagDialog({ onTagCreate, onAddedTag }: NewTagDialogProps) {
  const { t } = useMimicTranslation('tags');
  const [form] = Form.useForm<TagFormFields>();
  const [isLoading, setIsLoading] = useState(false);

  const onFormFinish = async () => {
    const name = form.getFieldValue('name');
    setIsLoading(true);
    onTagCreate(name)
      .then((tag) => {
        onAddedTag(tag);
      })
      .catch((e: ApiError) => {
        setIsLoading(false);
        if (e instanceof ApiError) {
          const message = getValidationMessage(e, 'name');
          form.setFields(validationErrors<TagFormFields>({ name: message }));
        }
      });
  };

  const okButton = (
    <Button
      loading={isLoading}
      type="primary"
      htmlType="submit"
      dataTestId="add-tag-submit"
      style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}
    >
      {t('addTag.submitButton')}
    </Button>
  );
  return (
    <Card padding="md">
      <TagForm form={form} loading={isLoading} onFinish={onFormFinish} okButton={okButton} horizontal />
    </Card>
  );
}
