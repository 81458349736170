/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export function useGetNodeConfigQuery(tenantId: string, configId: string) {
  return useQuery({
    queryKey: ['node-config', configId],
    queryFn: async () => {
      if (!tenantId || !configId) {
        return null;
      }
      return mgmtPlaneAPIClient.nodeConfigs.getConfig({ tenantId, configId });
    },
    enabled: !!tenantId && !!configId,
  });
}
