/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigAssignmentResponseSchema } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type AssignConfigToNodesRequest = {
  nodeIDs: string[];
  configID: string;
  revisionNumber: number;
  jobName?: string;
};

export type AssignConfigRevisionMutationConfig = {
  onSuccess?: (data: ConfigAssignmentResponseSchema, variables: AssignConfigToNodesRequest) => void;
  onError?: (error: unknown, variables: AssignConfigToNodesRequest) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
};

export function useAssignConfigRevisionMutation(tenantId: string, config?: AssignConfigRevisionMutationConfig) {
  const queryClient = useQueryClient();

  return useMutation<ConfigAssignmentResponseSchema, unknown, AssignConfigToNodesRequest>({
    mutationFn: (assignRequest: AssignConfigToNodesRequest) => {
      return mgmtPlaneAPIClient.nodeConfigs.assignNodeConfig({
        tenantId,
        configId: assignRequest.configID,
        requestBody: {
          nodeIds: assignRequest.nodeIDs,
          configRevisionNumber: assignRequest.revisionNumber,
          jobName: assignRequest.jobName,
        },
      });
    },
    onSuccess: (data, variables) => {
      if (config?.onSuccess) {
        config.onSuccess(data, variables);
      }
      queryClient.invalidateQueries({ queryKey: ['nodes'] });
      queryClient.invalidateQueries({ queryKey: ['node-configs'] });
    },
    onError: config?.onError,
    onSettled: config?.onSettled,
  });
}
