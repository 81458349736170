import { useEffect } from 'react';
import { useMessage } from '@/primitives/message';
import { useMimicTranslation } from '../translation/useMimicTranslation';

export type ShowErrorMessageProps = {
  showError: boolean;
  errorMessage?: string;
};

export function useShowErrorMessage({ showError, errorMessage }: ShowErrorMessageProps) {
  const [message] = useMessage();
  const { t } = useMimicTranslation('global');

  const displayedErrorMessage = errorMessage || t('ops');

  useEffect(() => {
    if (showError) {
      message.error(displayedErrorMessage);
    }
  }, [showError, message, displayedErrorMessage]);

  return message.error;
}
