/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { queryOptions } from '@tanstack/react-query';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export function getUserOptions(tenantId: string, userId: string) {
  return queryOptions({
    queryKey: ['user', userId],
    queryFn: () => mgmtPlaneAPIClient.users.getUserById({ tenantId, id: userId }),
    enabled: Boolean(tenantId),
    refetchOnWindowFocus: false,
  });
}

export type GetUserOptions = ReturnType<typeof getUserOptions>;
