/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Typography } from 'antd';
import { FontSize, tokens } from '@/theme';

const { Text: AntDText } = Typography;

export type CodeProps = {
  size?: FontSize;
  multiline?: boolean;
  noBackground?: boolean;
  children?: React.ReactNode;
  dataTestId?: string;
};

export function Code({ size, multiline, noBackground, children, dataTestId }: CodeProps) {
  const fontSize = size ? tokens.size.font[size] : 'inherit';
  const fontWeight = 300;
  const lineHeight = `${tokens.size.space.lg}px`;
  if (multiline) {
    return (
      <AntDText style={{ fontSize, fontWeight }} data-testid={dataTestId}>
        <pre style={{ margin: 0, lineHeight, fontSize }}>{children}</pre>
      </AntDText>
    );
  }
  if (noBackground) {
    // must override here because this will not use Ant code style
    const { fontFamily } = tokens.font.monospace;
    return <AntDText style={{ fontSize, fontFamily, fontWeight }}>{children}</AntDText>;
  }
  return (
    <AntDText style={{ fontSize, fontWeight }} data-testid={dataTestId}>
      <code style={{ margin: 0, fontSize }}>{children}</code>
    </AntDText>
  );
}
