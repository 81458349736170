/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ReactNode } from 'react';
import { Container } from '@/primitives/Container';
import { TShirtSize } from '@/theme';

export type ContentContainerProps = {
  width?: string;
  padding?: TShirtSize;
  children: ReactNode;
};

export function ContentContainer({ width, padding, children }: ContentContainerProps) {
  return (
    <Container horizontal padding={padding || 'xxl'}>
      <div style={{ width: width || '1024px' }}>{children}</div>
    </Container>
  );
}
