/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { queryOptions } from '@tanstack/react-query';
import { mgmtPlaneAPIClient, PaginationParams } from '@/client/mgmtPlane';
import { ActivitiesFilter } from '@/client';

export function getActivityListOptions(tenantId: string, filters: ActivitiesFilter, settings: PaginationParams) {
  return queryOptions({
    queryKey: ['activities', tenantId, settings.number, settings.size],
    queryFn: () =>
      mgmtPlaneAPIClient.activities.getActivities({
        tenantId,
        page: settings.number,
        pageSize: settings.size,
        filters,
      }),
    enabled: Boolean(tenantId),
  });
}

export type GetActivityListOptions = ReturnType<typeof getActivityListOptions>;
