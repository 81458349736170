/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { AppRoutes } from './AppRoutes';
import { MimicApp } from './MimicApp';

function App() {
  return (
    <MimicApp>
      <AppRoutes />
    </MimicApp>
  );
}

export default App;
