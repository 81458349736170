/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Layout as AntDLayout } from 'antd';

const { Content: AntDContent } = AntDLayout;

export type ContentProps = {
  children: React.ReactNode;
};

export function Content({ children }: ContentProps) {
  return <AntDContent style={{ overflow: 'auto' }}>{children}</AntDContent>;
}
