/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';
import { NodeConfigFilters, NodeConfigSortableFields } from '@/client';
import { mgmtPlaneAPIClient, PaginationParams } from '@/client/mgmtPlane';

export type NodeConfigsQueryParams = {
  tenantId?: string;
  filters: NodeConfigFilters;
  sort: NodeConfigSortableFields;
  settings: PaginationParams;
};

export function useNodeConfigsQuery({ tenantId, filters, sort, settings }: NodeConfigsQueryParams) {
  return useQuery({
    queryKey: ['node-configs', tenantId, settings.number, settings.size, sort, filters],
    queryFn: async () => {
      return mgmtPlaneAPIClient.nodeConfigs.getConfigs({
        tenantId: tenantId!,
        page: settings.number,
        pageSize: settings.size,
        filters,
        sort,
      });
    },
    staleTime: 5 * 1000,
    enabled: !!tenantId,
  });
}
