/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import Highlighter from 'react-highlight-words';

type MimicHighlighterProps = {
  searchText?: string;
  text: string;
};

export default function MimicHighlighter({ searchText, text }: MimicHighlighterProps) {
  if (!searchText) return text;

  return (
    <Highlighter
      highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
    />
  );
}
