/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

let initialized = false;

export function configSentry(
  sentryEnabled: boolean,
  sentryDsn: string,
  environment: string,
  releaseName: string,
  tracesSampleRate = 0.1,
  replaysSessionSampleRate = 0.1,
  replaysOnErrorSampleRate = 1.0,
) {
  if (!sentryEnabled || !sentryDsn || initialized) return;

  Sentry.init({
    environment,
    dsn: sentryDsn,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate,
    // Set 'tracePropagationTargets' to configure trace to localhost and *.mimic.com
    tracePropagationTargets: ['localhost', /^https:\/\/api\.(\w+\.)?mimic\.com\/api/],
    // Session Replay
    replaysSessionSampleRate, // % of sessions that get recorded for replay (no matter if there are errors or not)
    replaysOnErrorSampleRate, // % of sessions that get recorded for replay (only if there are errors)
    release: releaseName,
  });
  initialized = true;
}
