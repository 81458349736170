/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Fragment, Key, ReactNode } from 'react';

export type WrapKeyParams = {
  key: Key;
  children: ReactNode;
};

export function WrapKey({ key, children }: WrapKeyParams) {
  return <Fragment key={key}>{children}</Fragment>;
}
