/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { CheckOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { MouseEventHandler, useState } from 'react';
import { Tenant } from '@/client';
import { Avatar } from '@/primitives/Avatar';
import { Text } from '@/primitives/Text';
import { tokens } from '@/theme';

export type TenantOptionProps = {
  selectedTenant: Tenant;
  tenant: Tenant;
};

export function TenantOption({ selectedTenant, tenant }: TenantOptionProps) {
  const [nameVisible, setNameVisible] = useState(false);

  const toggleNameVisible: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setNameVisible(!nameVisible);
  };

  return (
    <div style={{ display: 'flex', gap: 10, padding: '10px 0' }} data-testid={`tenant-option-${tenant.id}`}>
      <div>
        {tenant.id === selectedTenant?.id ? (
          <CheckOutlined data-testid="tenant-selected" />
        ) : (
          <CheckOutlined data-testid="tenant-not-selected" style={{ color: 'transparent' }} />
        )}
      </div>
      <div style={{ flexGrow: 1, display: 'flex', gap: 10, overflow: 'hidden', maxWidth: '165px' }}>
        <Avatar
          size="xxs"
          text={tenant.name.charAt(0).toUpperCase()}
          color={tokens.color.gold.gold03}
          backgroundColor={tokens.color.text.textSubtle}
          dataTestId="tenant-avatar"
          shape="square"
        />
        {nameVisible && (
          <Text data-testid="tenant-name-visible" ellipsis>
            {tenant.name}
          </Text>
        )}
        {!nameVisible && (
          <Text data-testid="tenant-name-hidden" ellipsis>
            {tenant?.name.split('').map(() => '•')}
          </Text>
        )}
      </div>
      {!nameVisible && <EyeOutlined data-testid="show-tenant-name" onClick={toggleNameVisible} />}
      {nameVisible && <EyeInvisibleOutlined data-testid="hide-tenant-name" onClick={toggleNameVisible} />}
    </div>
  );
}
