/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { NodeViewCard } from '@/primitives/NodeViewCard';

export type NodeViewPageCardProps = {
  title?: string;
  children: React.ReactNode;
  dataTestId?: string;
  bodyStyles?: React.CSSProperties;
};

export function NodeViewPageCard({ title, children, dataTestId, bodyStyles }: NodeViewPageCardProps) {
  return (
    <NodeViewCard title={title} gap="xxs" dataTestId={dataTestId} bodyStyles={bodyStyles}>
      {children}
    </NodeViewCard>
  );
}
