/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Row as AntDRow, RowProps as AntDRowProps } from 'antd';

export type RowProps = {
  children: React.ReactNode;
  gutter?: AntDRowProps['gutter'];
  justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between';
  align?: 'top' | 'middle' | 'bottom';
  style?: React.CSSProperties;
};

export function Row({ children, gutter, justify, align, style }: RowProps) {
  const calculatedStyle = style || {};
  if (!calculatedStyle.width) {
    calculatedStyle.width = '100%';
  }
  return (
    <AntDRow gutter={gutter} justify={justify} align={align} style={calculatedStyle}>
      {children}
    </AntDRow>
  );
}
