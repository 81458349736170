/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { MoreOutlined } from '@ant-design/icons';
import { ActionType, Role, RoleAction } from './MemberTable';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { User } from '@/client';
import { Button } from '@/primitives/Button';
import { Dropdown, MenuProps } from '@/primitives/Dropdown';

export type MemberActionDropdownParams = {
  user: User;
  currentUser: User;
  onChangeRole: (user: User, role: Role) => void;
  onChangeRemoved: (user: User, removed: boolean) => void;
  onUpdateClick: (user: User) => void;
};

export function MemberActionDropdown({
  user,
  currentUser,
  onChangeRole,
  onUpdateClick,
  onChangeRemoved,
}: MemberActionDropdownParams) {
  const { t } = useMimicTranslation('members');
  const handleActionClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case ActionType.MAKE_ADMIN:
        onChangeRole(user, 'admin');
        break;
      case ActionType.MAKE_EDITOR:
        onChangeRole(user, 'editor');
        break;
      case ActionType.MAKE_VIEWER:
        onChangeRole(user, 'viewer');
        break;
      case ActionType.UPDATE:
        onUpdateClick(user);
        break;
      case ActionType.REMOVE:
        onChangeRemoved(user, !user.removed);
        break;
      default:
        break;
    }
  };

  const userRole = user.role;
  const items: MenuProps['items'] = [
    {
      label: t('actions.makeAdmin', { user: user.displayName }),
      key: ActionType.MAKE_ADMIN,
      'data-testid': 'role-option-admin',
    },
    {
      label: t('actions.makeEditor', { user: user.displayName }),
      key: ActionType.MAKE_EDITOR,
      'data-testid': 'role-option-editor',
    },
    {
      label: t('actions.makeViewer', { user: user.displayName }),
      key: ActionType.MAKE_VIEWER,
      'data-testid': 'role-option-viewer',
    },
    {
      label: t('actions.update'),
      key: ActionType.UPDATE,
      'data-testid': 'update-member-open',
    },
    {
      label: user.removed ? t('actions.restore') : t('actions.remove'),
      key: ActionType.REMOVE,
      'data-testid': 'remove-user',
    },
  ].filter((item) => !(RoleAction[userRole] === item.key));

  const menu = {
    items,
    onClick: handleActionClick,
  };

  if (user.id !== currentUser.id) {
    return (
      <Dropdown menu={menu}>
        <Button type="text" dataTestId={`member-options-${user.id}`}>
          <MoreOutlined />
        </Button>
      </Dropdown>
    );
  }
}
