/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Table as AntDTable, TableProps as AntDTableProps } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import './Table.css';

export type TableProps<RecordType> = {
  dark?: boolean;
  loading?: boolean;
  dataSource: AntDTableProps<RecordType>['dataSource'];
  columns: AntDTableProps<RecordType>['columns'];
  rowKey?: AntDTableProps<RecordType>['rowKey'];
  onRow?: AntDTableProps<RecordType>['onRow'];
  expandable?: AntDTableProps<RecordType>['expandable'];
  onChange?: AntDTableProps<RecordType>['onChange'];
  pagination?: AntDTableProps<RecordType>['pagination'];
  style?: AntDTableProps<RecordType>['style'];
  rowSelection?: AntDTableProps<RecordType>['rowSelection'];
  ['data-testid']?: string;
};

export function Table<RecordType extends AnyObject = AnyObject>({
  dark,
  loading,
  dataSource,
  columns,
  rowKey,
  onRow,
  expandable,
  onChange,
  pagination,
  style,
  'data-testid': dataTestId,
  rowSelection,
}: TableProps<RecordType>) {
  let className = '';
  if (dark) {
    className = 'table-dark';
  }
  return (
    <AntDTable
      data-testid={dataTestId}
      onChange={onChange}
      rowKey={rowKey}
      onRow={onRow}
      expandable={expandable}
      loading={loading}
      style={style}
      className={className}
      pagination={pagination}
      dataSource={dataSource}
      columns={columns}
      rowSelection={rowSelection}
    />
  );
}
