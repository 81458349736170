/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Badge, BadgeProps } from 'antd';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { Space } from '@/primitives/Space';
import { ConnectivityState } from '@/client';
import { Text } from '@/primitives/Text';
import { badge } from '@/theme';

export type ConnectivityStateBadgeProps = {
  state: ConnectivityState;
  disabled?: boolean;
  size?: 'md' | 'lg' | 'xl';
  showLabel?: boolean;
};

type BadgeType = Record<ConnectivityStateBadgeProps['state'], BadgeProps['status']>;

const badgeType: BadgeType = {
  connected: 'success',
  disconnected: 'error',
  degraded: 'warning',
};

const badgeColor = {
  disabled: {
    connected: badge.badgeGreenDotSubtle,
    disconnected: badge.badgeRedDotSubtle,
    degraded: badge.badgeYellowDotSubtle,
  },
  enabled: {
    connected: badge.badgeGreenDot,
    disconnected: badge.badgeRedDot,
    degraded: badge.badgeYellowDot,
  },
};

function getIconForStatus(name: ConnectivityStateBadgeProps['state'], disabled?: boolean) {
  const bgColor = disabled ? badgeColor.disabled : badgeColor.enabled;
  if (!(name in badgeType)) {
    return <Badge status="processing" data-testid="node-status" data-status="processing" />;
  }
  return (
    <Badge color={bgColor[name]} status={badgeType[name]} data-testid="node-status" data-status={badgeType[name]} />
  );
}

const minHeights = {
  md: '14px',
  lg: 'inherit',
  xl: '36px',
};

export function ConnectivityStateBadge({
  state,
  disabled,
  size = 'md',
  showLabel = true,
}: ConnectivityStateBadgeProps) {
  const icon = getIconForStatus(state, disabled);
  const { t } = useMimicTranslation('node');

  const text = showLabel ? (
    <Text type={disabled ? 'disabled' : undefined} size={size}>
      {state ? t(`connectivityState.${state}`) : t('connectivityState.unknown')}
    </Text>
  ) : null;

  return (
    <Space dataTestId="connectivity-state-badge" minHeight={minHeights[size]}>
      {icon}
      {text}
    </Space>
  );
}
