/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Pagination as AntDPagination, PaginationProps as AntDPaginationProps } from 'antd';
import './Pagination.css';

export type PaginationProps = {
  current: AntDPaginationProps['current'];
  pageSize: AntDPaginationProps['pageSize'];
  total: AntDPaginationProps['total'];
  hideOnSinglePage?: AntDPaginationProps['hideOnSinglePage'];
  onChange: AntDPaginationProps['onChange'];
};

export function Pagination({ current, pageSize, total, hideOnSinglePage, onChange }: PaginationProps) {
  return (
    <AntDPagination
      current={current}
      defaultPageSize={pageSize}
      total={total}
      hideOnSinglePage={hideOnSinglePage}
      onChange={onChange}
    />
  );
}
