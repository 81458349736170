/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Node } from '@/client';
import { Flex } from '@/primitives/Flex';
import { Link } from '@/primitives/Link';
import { Text } from '@/primitives/Text';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { OsIcon } from '../Icons';
import { tokens } from '@/theme';

export type ConfigureNodesJobWizardSelectConfigRevisionSideBarProps = {
  tenantID: string;
  selectedNodes: Node[] | undefined;
};

export function ConfigureNodesJobWizardSelectConfigRevisionSideBar({
  tenantID,
  selectedNodes,
}: ConfigureNodesJobWizardSelectConfigRevisionSideBarProps) {
  const { t } = useMimicTranslation('configureNodesJobWizard');

  if (!selectedNodes) {
    return null;
  }

  return (
    <div style={{ padding: '24px', minWidth: '268px' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderBottom: '1px solid rgba(25, 25, 25, 0.10)',
          gap: '4px',
          paddingBottom: '16px',
        }}
      >
        <Text type="default" strong>
          {`${selectedNodes.length} ${t('nodesSelected')}`}
        </Text>
        <Flex gap="xs" align="center">
          <OsIcon name={selectedNodes[0].operatingSystem} color={tokens.color.text.textSubtle} />
          <Text type="subtle" size="md">
            {selectedNodes[0].operatingSystem.slice(0, 1).toUpperCase() + selectedNodes[0].operatingSystem.slice(1)}
          </Text>
        </Flex>
      </div>
      <div style={{ paddingTop: '20px' }}>
        <Flex vertical gap="sm">
          {selectedNodes.map((node) => (
            <Link key={node.id} to={`/tenants/${tenantID}/nodes/${node.id}`}>
              <Text type="link" size="md" mono>
                {node.hostname}
              </Text>
            </Link>
          ))}
        </Flex>
      </div>
    </div>
  );
}
