/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';
import { mgmtPlaneAPIClient, PaginationParams } from '@/client/mgmtPlane';
import { defineTypes } from '../filters';
import { JobFilters, JobSortableFields } from '@/client';

export const JobFiltersTypes = defineTypes<JobFilters>({
  name: 'string',
  targetConfig: 'string',
  operatingSystem: 'string',
  notificationDismissed: 'boolean',
  nodeConfigId: 'string',
  status: 'string',
});

export type GetJobsQueryParams = {
  tenantId: string;
  filters: JobFilters;
  sort: JobSortableFields;
  settings: PaginationParams;
};

export function useGetJobsQuery({ tenantId, filters, sort, settings }: GetJobsQueryParams) {
  return useQuery({
    queryKey: ['jobs', tenantId, settings.number, settings.size, sort, filters],
    queryFn: async () => {
      return mgmtPlaneAPIClient.jobs.getJobs({
        tenantId,
        page: settings.number,
        pageSize: settings.size,
        filters,
        sort,
      });
    },
    enabled: !!tenantId,
  });
}
