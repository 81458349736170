/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { TypeOpen } from 'antd/es/message/interface';
import { ReactNode } from 'react';
import { StateCreator } from 'zustand';

export type MessageInstance = {
  feedback: TypeOpen;
  info: TypeOpen;
  warning: TypeOpen;
  success: TypeOpen;
  error: TypeOpen;
};

export type MessageContext = {
  context: ReactNode;
  api: MessageInstance;
};

export interface ToastMessagesSlice {
  namespaces: Record<string, MessageContext>;
  getMessageNamespace: (namespace: string) => MessageContext | undefined;
  setMessageNamespace: (namespace: string, messageContext: MessageContext) => void;
}

export const createToastMessagesSlice: StateCreator<ToastMessagesSlice, [], [], ToastMessagesSlice> = (set, get) => ({
  namespaces: {},
  getMessageNamespace: (namespace: string) => {
    return get().namespaces[namespace];
  },
  setMessageNamespace: (namespace: string, messageContext: MessageContext) => {
    return set(({ namespaces }) => {
      return { namespaces: { ...namespaces, [namespace]: messageContext } };
    });
  },
});
