/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import * as Sentry from '@sentry/react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { CLISetupRoute } from '@/routes/CLISetupRoute';
import { LogInRoute } from '@/routes/LogInRoute';
import { NodeListRoute } from '@/routes/Nodes/NodeListRoute';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { CenterLayout } from './CenterLayout';
import { MainLayout } from './MainLayout';
import { ActivityRoute } from './routes/Activity/ActivityRoute';
import { CLIAuthRoute } from './routes/CLIAuthRoute';
import { CLIDownloadRoute } from './routes/CLIDownloadRoute';
import { SwaggerUiRoute } from './routes/Docs/SwaggerUiRoute';
import { InviteMemberModal } from './routes/InviteMemberModal';
import { MembersRoute } from './routes/MembersRoute';
import { NodeViewRoute } from './routes/Nodes/NodeViewRoute';
import { NotificationsRoute } from './routes/Notifications/NotificationsRoute';
import { TagsRoute } from './routes/Tags/TagsRoute';
import { UpdateMemberModal } from './routes/UpdateMemberModal';
import './theme/tokens.css';
import { ConfigsRoute } from './routes/Nodes/ConfigsRoute';
import { SystemInfo } from './routes/SystemInfo';
import { NodeConfigurationDetailsRoute } from './routes/NodeConfigurationDetailsRoute';
import { NodeConfigurationRevisionSourceCodeRoute } from './routes/ConfigRevisionRoute';
import { JobDetailsRoute } from './routes/JobDetailsRoute';
import { HTTPError } from './components/HTTPError';
import { ConfigureNodesJobWizardRoute } from './routes/ConfigureNodesJobWizardRoute';
import { JobsRoute } from './routes/JobsRoute';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

function Error500() {
  Sentry.captureException(new Error('Error boundaries could not catch the error.'));
  return <HTTPError httpStatus="500" />;
}

export function AppRoutes() {
  const selectedTenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const router = sentryCreateBrowserRouter([
    {
      path: '/callback',
      errorElement: <Error500 />,
      element: <Navigate to={`/tenants/${selectedTenantID}/activity`} />,
    },
    {
      path: '/',
      element: <MainLayout />,
      errorElement: <Error500 />,
      children: [
        {
          index: true,
          element: <Navigate to={`/tenants/${selectedTenantID}/activity`} replace />,
        },
        {
          path: '/tenants/:tenantID/activity',
          element: <ActivityRoute />,
        },
        {
          path: '/tenants/:tenantID/job-configure-nodes',
          element: <ConfigureNodesJobWizardRoute />,
          children: [
            {
              index: true,
              path: '#select-nodes',
              element: <div>select nodes</div>,
            },
            {
              path: '#select-config',
              element: <div>select config</div>,
            },
            {
              path: '#review-job',
              element: <div>select config</div>,
            },
          ],
        },
        {
          path: '/tenants/:tenantID/jobs/',
          element: <JobsRoute />,
        },
        {
          path: '/tenants/:tenantID/jobs/:jobId',
          element: <JobDetailsRoute />,
        },
        {
          path: '/tenants/:tenantID/nodes',
          element: <NodeListRoute />,
        },
        {
          path: '/tenants/:tenantID/nodes/:id',
          element: <NodeViewRoute />,
        },
        {
          path: '/tenants/:tenantID/node-configs',
          element: <ConfigsRoute />,
        },
        {
          path: '/tenants/:tenantID/node-configs/:nodeConfigId',
          element: <NodeConfigurationDetailsRoute />,
        },
        {
          path: '/tenants/:tenantID/node-configs/:nodeConfigId/revisions/:configRevisionNumber',
          element: <NodeConfigurationRevisionSourceCodeRoute />,
        },
        {
          path: '/tenants/:tenantID/notifications',
          element: <NotificationsRoute />,
        },
        {
          element: <MembersRoute />,
          path: '/tenants/:tenantID/members',
          children: [
            {
              path: 'invite',
              element: <InviteMemberModal />,
            },
            {
              path: 'update/:userID',
              element: <UpdateMemberModal />,
            },
          ],
        },
        {
          path: '/tenants/:tenantID/tags',
          element: <TagsRoute />,
        },
        {
          path: '/tenants/:tenantID/mimic-cli/download',
          element: <CLIDownloadRoute />,
        },
        {
          path: '/tenants/:tenantID/mimic-cli/authenticate',
          element: <CLIAuthRoute />,
        },
        {
          path: '/tenants/:tenantID/docs/swagger',
          element: <SwaggerUiRoute />,
        },
        {
          path: '/system-info',
          element: <SystemInfo />,
        },
        {
          path: '*',
          element: <HTTPError httpStatus="404" />,
        },
      ],
    },
    {
      path: '/',
      element: <CenterLayout />,
      children: [
        {
          path: '/login',
          element: <LogInRoute />,
        },
        {
          path: '/cli-setup',
          element: <CLISetupRoute />,
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}
