/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Sorter } from './params';

const URL_SORT_PARAM = 'sort';

export function getSort<S = Array<string>>(searchParams: URLSearchParams, scope?: string): S {
  const sort = searchParams.get(scope ? `${scope}Sort` : URL_SORT_PARAM);
  if (!sort) {
    return [] as unknown as S;
  }
  return sort.replace(' ', '+').split(',') as S;
}

export function setSortParams<T>(searchParams: URLSearchParams, sort: Sorter<T>[], scope?: string) {
  const sortStr = sort
    .reduce<string[]>((acc, s) => {
      if (s.order === 'ascend') {
        acc.push(`+${s.field as string}`);
      } else if (s.order === 'descend') {
        acc.push(`-${s.field as string}`);
      }
      return acc;
    }, [])
    .join(',');
  if (sortStr === '') {
    searchParams.delete(scope ? `${scope}Sort` : URL_SORT_PARAM);
  } else {
    searchParams.set(scope ? `${scope}Sort` : URL_SORT_PARAM, sortStr);
  }
}

export function fromSorter<SortableFields, Resource>(sort?: Sorter<Resource>[]): SortableFields {
  if (!sort) {
    return [] as SortableFields;
  }
  return sort
    .filter((s) => {
      return s.field !== undefined;
    })
    .map((s) => {
      if (s.order === 'ascend') {
        return `+${s.field.toString()}`;
      }
      if (s.order === 'descend') {
        return `-${s.field.toString()}`;
      }
      return '';
    }) as SortableFields;
}
