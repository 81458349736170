/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Spin } from 'antd';

export function LoadingSpinnerFullPage() {
  return (
    <div
      style={{
        margin: 'auto',
      }}
      data-testid="loading-spinner-full-page"
    >
      <Spin />
    </div>
  );
}
