/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Col as AntDCol } from 'antd';

export type ColProps = {
  children?: React.ReactNode;
  span?: number | string;
  offset?: number | string;
  xs?: number | string;
  style?: React.CSSProperties;
};

export function Col({ children, span, offset, xs, style }: ColProps) {
  return (
    <AntDCol span={span} offset={offset} xs={xs} style={style}>
      {children}
    </AntDCol>
  );
}
