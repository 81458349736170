import { Switch as AntSwitch, SwitchProps as AntSwitchProps } from 'antd';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import './Switch.css';

export type SwitchProps = {
  checked?: AntSwitchProps['checked'];
  checkedChildren?: React.ReactNode;
  unCheckedChildren?: React.ReactNode;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: SwitchChangeEventHandler;
  dataTestId?: string;
  style?: {
    transform?: string;
    transformOrigin?: string;
    backgroundColor?: string;
  };
};

export function Switch({
  checked,
  checkedChildren,
  defaultChecked,
  unCheckedChildren,
  disabled,
  onChange,
  dataTestId,
  style,
}: SwitchProps) {
  return (
    <AntSwitch
      checked={checked}
      checkedChildren={checkedChildren}
      defaultChecked={defaultChecked}
      unCheckedChildren={unCheckedChildren}
      disabled={disabled}
      onChange={onChange}
      data-testid={dataTestId}
      style={style}
    />
  );
}
