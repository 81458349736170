/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form, Input } from 'antd';
import { FocusEventHandler } from 'react';
import { NodeConfig } from '@/client';
import { tokens } from '@/theme';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { NodeViewPageCard } from './Node/NodeViewPageCard';

export type ConfigurationNotesProps = {
  nodeConfig: NodeConfig;
  onNotesBlur: FocusEventHandler<HTMLTextAreaElement>;
};

export function ConfigurationNotes({ nodeConfig, onNotesBlur }: ConfigurationNotesProps) {
  const [form] = Form.useForm();
  const { t } = useMimicTranslation('nodeConfigurationDetail');

  const onBlur: FocusEventHandler<HTMLTextAreaElement> = (e) => {
    onNotesBlur(e);
    form.setFieldsValue({ description: e.target.value });
  };

  form.setFieldValue('description', nodeConfig.notes);

  return (
    <NodeViewPageCard title={t('notes')}>
      <Form form={form} layout="vertical">
        <Form.Item name="description" initialValue={nodeConfig.notes}>
          <Input.TextArea
            allowClear
            rows={4}
            onBlur={onBlur}
            placeholder={t('notesPlaceholder')}
            count={{ show: true, max: 280 }}
            maxLength={280}
            style={{
              backgroundColor: tokens.color.surface.surfaceHigh,
            }}
          />
        </Form.Item>
      </Form>
    </NodeViewPageCard>
  );
}
