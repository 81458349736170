/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { queryOptions } from '@tanstack/react-query';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { ApiError } from '@/client';

export function getNodeOptions(tenantId: string, id: string) {
  return queryOptions({
    queryKey: ['node', tenantId, id],
    queryFn: () => mgmtPlaneAPIClient.nodes.getNodeById({ tenantId, id }).then((res) => res.data),
    staleTime: 5 * 1000,
    enabled: !!tenantId,
    retry: (failureCount, error: ApiError) => failureCount < 3 && error.status !== 404,
  });
}
