import { UserInfo } from '@/client';
import { Avatar } from '@/primitives/Avatar';
import { Container } from '@/primitives/Container';
import { Text } from '@/primitives/Text';

function getInitial(userInfo: UserInfo) {
  const displayName = userInfo.displayName || userInfo.email;
  if (displayName) {
    return displayName[0].toLocaleUpperCase();
  }
  return '';
}

export type AvatarAndNameProps = {
  userInfo?: UserInfo;
  size?: 'sm' | 'md';
};

export function AvatarAndName({ userInfo, size }: AvatarAndNameProps) {
  if (!userInfo) return null;

  return (
    <Container horizontal gap="xs" style={{ height: '100%', alignItems: 'center' }}>
      <Avatar dataTestId="user-avatar" size="xxs" text={getInitial(userInfo)} />
      <Text size={size || 'md'} ellipsis type="subtle">
        {userInfo.displayName || userInfo.email}
      </Text>
    </Container>
  );
}
