/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { BuildOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ConfigRevision,
  ConfigRevisionFilters,
  ConfigRevisionsSortableFields,
  Node,
  NodeFilters,
  NodeSortableFields,
} from '@/client';
import { BackButton } from '@/components/Buttons/BackButton';
import { Text } from '@/primitives/Text';
import { useConfigRevisionsQuery } from '@/utils/hooks/useConfigRevisionsQuery';
import { useGetNodeConfigQuery } from '@/utils/hooks/useGetNodeConfigQuery';
import { NodesFilterTypes, useNodesQuery } from '@/utils/hooks/useNodesQuery';
import { useQueryParams } from '@/utils/hooks/useQueryParams';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { AssignedNodes } from '../components/AssignedNodes';
import { ConfigurationNotes, ConfigurationNotesProps } from '../components/ConfigurationNotes';
import { ConfigurationOverview } from '../components/ConfigurationOverview';
import { PageHeader } from '../components/PageHeader';
import { RevisionHistory } from '../components/RevisionHistory';
import { Col } from './Col';
import { Container } from './Container';
import { Flex } from './Flex';
import { Row } from './Row';
import { Skeleton } from './Skeleton';
import { useUpdateNodeConfigMutation } from '@/utils/hooks/useUpdateNodeConfigMutation';
import { useMessage } from './message';
import { GetUserListOptions } from '@/utils/hooks/getUserListOptions';
import { useSubscribeToEvents } from '@/utils/hooks/useSubscribeToEvents';

export type NodeConfigurationDetailsProps = {
  tenantId: string;
  nodeConfigId: string;
  userOptions: GetUserListOptions;
  pathToRevision: (configRevision: ConfigRevision) => string;
  getNodePath: (node: Node) => string;
  getPendingNodesPath: () => string;
};

export function NodeConfigurationDetails({
  tenantId,
  nodeConfigId,
  userOptions,
  pathToRevision,
  getNodePath,
  getPendingNodesPath,
}: NodeConfigurationDetailsProps) {
  const { t } = useMimicTranslation('nodeConfigurationDetail');
  const queryClient = useQueryClient();

  const [message] = useMessage();
  const { data: nodeConfigResponse, isLoading: isLoadingNodeConfig } = useGetNodeConfigQuery(tenantId, nodeConfigId);

  useSubscribeToEvents(
    ['node-config:updated', 'node-config-revision:created', 'node-config:assigned'],
    (response: { nodeConfigID: string; nodeIDs?: string[] }) => {
      if (response.nodeConfigID !== nodeConfigId) return;
      queryClient.invalidateQueries({
        queryKey: ['node-config', nodeConfigId],
      });
    },
  );

  const {
    filters: nodeFilters,
    sort: nodesSort,
    paginationParams: nodePaginationParams,
    updateQueryParams: nodeUpdateQueryParams,
  } = useQueryParams<Node, NodeFilters, NodeSortableFields>(NodesFilterTypes, undefined, {
    appliedNodeConfigId: nodeConfigId,
  });

  const nodesQuery = useNodesQuery(tenantId, nodeFilters, nodesSort, nodePaginationParams);

  const { paginationParams: revisionsPaginationParams, updateQueryParams: revisionsUpdateQueryParams } = useQueryParams<
    ConfigRevision,
    ConfigRevisionFilters,
    ConfigRevisionsSortableFields
  >();
  const configRevisionsQuery = useConfigRevisionsQuery({
    tenantId,
    configId: nodeConfigId,
    filters: {},
    sort: [],
    settings: revisionsPaginationParams,
  });

  const { data: users } = useQuery(userOptions);

  const mutation = useUpdateNodeConfigMutation(tenantId, nodeConfigId, {
    onError: () => {
      message.error(t('updatingNotesFailed'));
    },
  });

  if (!nodeConfigResponse || isLoadingNodeConfig) return <Skeleton loading />;

  const { data: nodeConfig } = nodeConfigResponse;

  const onNotesBlur: ConfigurationNotesProps['onNotesBlur'] = (e) => {
    mutation.mutate({
      notes: e.target.value,
    });
  };

  return (
    <Flex vertical gap="md">
      <Container>
        <BackButton to={`/tenants/${tenantId}/node-configs`} />
      </Container>
      <Flex vertical gap="xs">
        <Text strong type="subtle">
          {t('title')}
        </Text>
        <PageHeader icon={<BuildOutlined />} title={<Text mono>{nodeConfig.name}</Text>} level={3} fontWeight="300" />
      </Flex>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <AssignedNodes
            nodeConfig={nodeConfig}
            tags={[]}
            users={users}
            onParamsChange={nodeUpdateQueryParams}
            nodesQuery={nodesQuery}
            getPendingNodesPath={getPendingNodesPath}
            getNodePath={getNodePath}
          />
        </Col>
        <Col span={12}>
          <RevisionHistory
            configRevisionsQuery={configRevisionsQuery}
            pathToRevision={pathToRevision}
            onParamsChange={revisionsUpdateQueryParams}
          />
        </Col>
        <Col span={12}>
          <Flex vertical gap="sm">
            <ConfigurationOverview nodeConfig={nodeConfig} pathToRevision={pathToRevision} />
            <ConfigurationNotes nodeConfig={nodeConfig} onNotesBlur={onNotesBlur} />
          </Flex>
        </Col>
      </Row>
    </Flex>
  );
}
