/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Outlet, useNavigate } from 'react-router-dom';
import { User, UserFilters, UserResponse } from '@/client';
import { Role } from '@/components/Members/MemberTable';
import { MembersListPage } from '@/components/Members/MembersListPage';
import { useMessage } from '@/primitives/message';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { useUpdateUserMutation } from '@/utils/hooks/useUpdateUserMutation';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { defineTypes } from '@/utils/filters';
import { GetUserListOptions, getUserListOptions } from '@/utils/hooks/getUserListOptions';
import { ContentContainer } from '@/components/ContentContainer';
import { useQueryParams } from '@/utils/hooks/useQueryParams';
import { PaginationParams } from '@/client/mgmtPlane';

const UserFilterTypes = defineTypes<UserFilters>({
  removed: 'boolean',
});

export function MembersRoute() {
  const { t } = useMimicTranslation('members');
  const navigate = useNavigate();
  const currentUser = useMgmtPlaneStateStore((state) => state.userProfile);
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const [message] = useMessage();
  const { filters, paginationParams, updateQueryParams } = useQueryParams<User, UserFilters>(UserFilterTypes);

  const countPagination: PaginationParams = {
    number: 1,
    size: 1,
  };

  let activeOptions: GetUserListOptions;
  let removedOptions: GetUserListOptions;

  if (filters.removed) {
    activeOptions = getUserListOptions(tenantID!, ['+displayName'], { removed: false }, countPagination);
    removedOptions = getUserListOptions(tenantID!, ['+displayName'], filters, paginationParams);
  } else {
    activeOptions = getUserListOptions(tenantID!, ['+displayName'], filters, paginationParams);
    removedOptions = getUserListOptions(tenantID!, ['+displayName'], { removed: true }, countPagination);
  }

  const { mutate } = useUpdateUserMutation(tenantID!, {
    onSuccess: ({ data: userUpdated }: UserResponse) => {
      message.success(t('feedback.userUpdated', { user: `${userUpdated.displayName}` }));
    },
    onError: (_, updateUserDto) => {
      const displayName = `${updateUserDto.firstName} ${updateUserDto.lastName}`;
      message.error(t('feedback.errorOnUpdate', { user: `${displayName}` }));
    },
  });

  const updateUserRole = (user: User, role: Role) => {
    mutate({ ...user, role });
  };

  const updateUserRemoved = (user: User, removed: boolean) => {
    mutate({ ...user, removed });
  };

  const openInviteModal = () => {
    navigate(`/tenants/${tenantID}/members/invite`);
  };

  const openMemberUpdateModal = (user: User) => {
    navigate(`/tenants/${tenantID}/members/update/${user.id}`);
  };

  return (
    <ContentContainer>
      <MembersListPage
        currentUser={currentUser!}
        activeOptions={activeOptions}
        removedOptions={removedOptions}
        filters={filters}
        onInviteClick={openInviteModal}
        onUpdateClick={openMemberUpdateModal}
        onChangeRemoved={updateUserRemoved}
        onChangeRole={updateUserRole}
        onParamsChange={updateQueryParams}
      />
      <Outlet />
    </ContentContainer>
  );
}
