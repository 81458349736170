import dayjs from 'dayjs';
import { useState } from 'react';
import { Job, JobNode, JobNodeFilters, JobNodeSortableFields, JobStatus } from '@/client';
import { PaginationParams } from '@/client/mgmtPlane';
import { Link } from '@/primitives/Link';
import { Text } from '@/primitives/Text';
import { useJobNodesQuery } from '@/utils/hooks/useJobNodesQuery';
import { UpdateParams } from '@/utils/params';
import { fromSorter } from '@/utils/sort';
import { MimicTranslationFunction, useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { JobNodeStatus } from '../JobDetails/JobNodeStatus';
import { PaginatedTable } from '../PaginatedTable';

type JobListRowExpandedProps = {
  job: Job;
};

export function JobListRowExpanded({ job }: JobListRowExpandedProps) {
  const { t } = useMimicTranslation('jobs');
  const [jobNodeFilters, setJobNodeFilters] = useState<Partial<JobNodeFilters>>({});
  const [nodeConfigSort, setNodeConfigSort] = useState<JobNodeSortableFields>(['+hostname']);
  const [nodeConfigPage, setNodeConfigPage] = useState<PaginationParams>({ number: 1, size: 10 });

  const jobNodesQuery = useJobNodesQuery({
    jobId: job.id,
    tenantId: job.tenantId,
    filters: jobNodeFilters,
    sort: nodeConfigSort,
    settings: nodeConfigPage,
  });

  const updateQueryParams: UpdateParams<JobNode> = ({ filters, sort, paginationParams }) => {
    if (filters) setJobNodeFilters(filters);
    if (paginationParams) setNodeConfigPage(paginationParams);
    if (sort) {
      const sortFields = fromSorter<JobNodeSortableFields, JobNode>(sort);
      setNodeConfigSort(sortFields);
    }
  };

  const columns = getJobNodesColumn(job, t);

  return (
    <PaginatedTable
      paginatedResource={jobNodesQuery}
      columns={columns}
      rowKey={(config) => config.id}
      data-testid="configuration-table"
      onParamsChange={updateQueryParams}
    />
  );
}

function getJobNodesColumn(job: Job, t: MimicTranslationFunction<'jobs'>) {
  return [
    {
      title: <span data-testid="hostName">{t('jobNodes.hostName')}</span>,
      dataIndex: 'hostname',
      sorter: true,
      render: (hostname: string, jobNode: JobNode) => {
        return (
          <Link
            to={`/tenants/${job.tenantId}/nodes/${jobNode.nodeId}`}
            style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
            dataTestId="node-link"
          >
            <Text type="link" size="md">
              {hostname}
            </Text>
          </Link>
        );
      },
    },
    {
      title: (
        <span data-testid="nodeVersion">
          {t('jobNodes.nodeVersion', { startDate: `${dayjs(job.dateCreated).format('D MMM YYYY')}` })}
        </span>
      ),
      dataIndex: 'nodeVersion',
      sorter: true,
      render: (nodeVersion: string) => {
        return (
          <Text type="default" size="md">
            {nodeVersion}
          </Text>
        );
      },
    },
    {
      title: <span data-testid="status">{t('jobNodes.status')}</span>,
      dataIndex: 'status',
      sorter: true,
      render: (status: JobStatus) => {
        return <JobNodeStatus status={status} />;
      },
    },
  ];
}
