/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import MimicLogoSvg from './MimicLogo.svg?react';
import MimicLogoStyles from './MimicLogo.module.css';

export type MimicLogoProps = {
  width?: string;
  height?: string;
};

export function MimicLogo({ width, height }: MimicLogoProps) {
  return (
    <MimicLogoSvg
      style={{
        width,
        height,
      }}
      className={`${MimicLogoStyles.logo} anticon`}
    />
  );
}
