/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Typography } from 'antd';
import type { TitleProps as AntDTitleProps } from 'antd/es/typography/Title';
import { CSSProperties } from 'react';

const { Title: AndtDTitle } = Typography;

export type TitleProps = {
  level?: AntDTitleProps['level'];
  lineHeight?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  ['data-testid']?: string;
};

export function Title({ level, lineHeight, style, children, 'data-testid': dataTestId }: TitleProps) {
  const mergedStyles = {
    margin: 0,
    marginBottom: 0,
    lineHeight,
    ...style,
  };
  return (
    <AndtDTitle level={level} style={mergedStyles} data-testid={dataTestId}>
      {children}
    </AndtDTitle>
  );
}
