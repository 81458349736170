/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';
import type { ColumnsType } from 'antd/es/table';
import { BuildOutlined } from '@ant-design/icons';
import { RelativeTime } from '@/components/RelativeTime';
import { NodeLifecycleEvent } from '@/client';
import { getNodeLifeCycleEvents } from '@/utils/hooks/getNodeLifeCycleEvents';
import { MimicTranslationFunction, useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { UpdateParams } from '@/utils/params';
import { PaginatedTable } from '@/components/PaginatedTable';
import { Text } from '@/primitives/Text';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { Link } from '@/primitives/Link';
import { Flex } from '@/primitives/Flex';

export type LifecycleEventsProps = {
  options: ReturnType<typeof getNodeLifeCycleEvents>;
  onParamsChange: UpdateParams<NodeLifecycleEvent, unknown>;
};

export function LifecycleEvents({ options, onParamsChange }: LifecycleEventsProps) {
  const { t } = useMimicTranslation('node');
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const lifecycleEventsQuery = useQuery(options);

  const columns: ColumnsType<NodeLifecycleEvent> = [
    {
      title: t('events'),
      dataIndex: 'msg',
      key: 'eventType',
      render: (_value, lifecycleEvent) => {
        return eventMessage(lifecycleEvent, tenantID!, t);
      },
    },
    {
      title: t('date'),
      dataIndex: 'eventTimestamp',
      key: 'eventTimestamp',
      render: (date: string) => {
        return <RelativeTime date={date} />;
      },
    },
  ];

  return (
    <PaginatedTable
      paginatedResource={lifecycleEventsQuery}
      columns={columns}
      rowKey={(node) => node.id}
      style={{ width: '100%' }}
      onParamsChange={onParamsChange}
    />
  );
}

function eventMessage(
  lifecycleEvent: NodeLifecycleEvent,
  tenantId: string,
  t: MimicTranslationFunction<'node'>,
): React.ReactNode | string {
  const { eventType, configRevision } = lifecycleEvent;

  if (eventType === 'EVENT_TYPE_LIFECYCLE_NODE_CONFIG_APPLIED') {
    return (
      <Flex gap="xxs" align="center" wrap>
        <Text type="default">{t('eventsTable.appliedConfig')}</Text>
        <Link to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}`}>
          <BuildOutlined style={{ marginRight: '4px' }} />
          {configRevision?.nodeConfigName}
        </Link>
        <Text type="default">·</Text>
        <Link
          to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}/revisions/${configRevision?.revisionNumber}`}
        >{`#${configRevision?.revisionNumber}`}</Link>
      </Flex>
    );
  }

  if (eventType === 'EVENT_TYPE_LIFECYCLE_NODE_CONFIG_REJECTED') {
    return (
      <Flex gap="xxs" align="center" wrap>
        <Text type="default">{t('eventsTable.rejectedConfig')}</Text>
        <Link to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}`}>
          <BuildOutlined style={{ marginRight: '2px' }} />
          {configRevision?.nodeConfigName}
        </Link>
        <Text type="default">·</Text>
        <Link
          to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}/revisions/${configRevision?.revisionNumber}`}
        >{`#${configRevision?.revisionNumber}`}</Link>
      </Flex>
    );
  }

  if (eventType === 'EVENT_TYPE_LIFECYCLE_NODE_CONFIG_PENDING') {
    return (
      <Flex gap="xxs" align="center" wrap>
        <Text type="default">{t('eventsTable.pendingConfig')}</Text>
        <Link to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}`}>
          <BuildOutlined style={{ marginRight: '2px' }} />
          {configRevision?.nodeConfigName}
        </Link>
        <Text type="default">·</Text>
        <Flex gap="none">
          <Link
            to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}/revisions/${configRevision?.revisionNumber}`}
          >{`#${configRevision?.revisionNumber}`}</Link>
          <Text type="default">…</Text>
        </Flex>
      </Flex>
    );
  }

  if (eventType === 'EVENT_TYPE_LIFECYCLE_NODE_CONFIG_OBTAINED') {
    return (
      <Flex gap="xxs" align="center" wrap>
        <Text type="default">{t('eventsTable.obtainedConfig')}</Text>
        <Link to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}`}>
          <BuildOutlined style={{ marginRight: '2px' }} />
          {configRevision?.nodeConfigName}
        </Link>
        <Text type="default">·</Text>
        <Flex gap="none">
          <Link
            to={`/tenants/${tenantId}/node-configs/${configRevision?.nodeConfigID}/revisions/${configRevision?.revisionNumber}`}
          >{`#${configRevision?.revisionNumber}`}</Link>
          <Text type="default">…</Text>
        </Flex>
      </Flex>
    );
  }

  return lifecycleEvent.msg;
}
