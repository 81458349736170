/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Button as AntDButton, ButtonProps as AntDButtonProps } from 'antd';
import { CSSProperties, KeyboardEventHandler, MouseEventHandler } from 'react';
import './Button.css';

export type ButtonProps = {
  className?: string;
  type: 'default' | 'primary' | 'dashed' | 'link' | 'text' | 'header-link' | 'inline-link';
  htmlType?: AntDButtonProps['htmlType'];
  icon?: AntDButtonProps['icon'];
  style?: CSSProperties;
  children: React.ReactNode;
  onClick?: MouseEventHandler | undefined;
  onKeyDown?: KeyboardEventHandler | undefined;
  dataTestId?: string;
  disabled?: boolean;
  block?: boolean;
  loading?: boolean;
  href?: string;
};

export function Button({
  className,
  type,
  htmlType,
  icon,
  style,
  children,
  onClick,
  onKeyDown,
  block,
  disabled,
  loading,
  dataTestId,
  href,
}: ButtonProps) {
  const combinedStyle: CSSProperties = {
    ...(style || {}),
    color: type === 'header-link' ? 'white' : undefined,
    boxShadow: 'unset',
  };

  const antType = type === 'header-link' || type === 'inline-link' ? 'link' : type;

  if (type === 'inline-link' && !('padding' in combinedStyle)) {
    combinedStyle.padding = 0;
  }
  return (
    <AntDButton
      className={className}
      htmlType={htmlType}
      icon={icon}
      type={antType}
      disabled={disabled}
      onClick={onClick}
      onKeyDown={onKeyDown}
      style={combinedStyle}
      data-testid={dataTestId}
      block={block}
      loading={loading}
      href={href}
    >
      {children}
    </AntDButton>
  );
}
