/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Node } from '@/client';

const selectedNodesKey = 'configureNodes.selectedNodes';
const selectedConfigRevisionIdKey = 'configureNodes.selectedConfigRevisionInfo';
const jobNameKey = 'configureNodes.jobName';

export const setJobWizardSelectedNodes = (nodes: Pick<Node, 'id' | 'operatingSystem'>[]) => {
  sessionStorage.setItem(
    selectedNodesKey,
    JSON.stringify(nodes.map((node) => ({ id: node.id, operatingSystem: node.operatingSystem }))),
  );
};

export const getJobWizardSelectedNodes = (): Node[] => {
  return JSON.parse(sessionStorage.getItem(selectedNodesKey) || '[]');
};

export const setJobWizardSelectedConfigRevisionInfo = (
  configRevisionInfo:
    | {
        configId: string;
        revisionId: string;
        revisionNumber: number;
      }
    | Record<string, never>,
) => {
  sessionStorage.setItem(selectedConfigRevisionIdKey, JSON.stringify(configRevisionInfo));
};

export const getJobWizardSelectedConfigRevisionInfo = () => {
  return JSON.parse(sessionStorage.getItem(selectedConfigRevisionIdKey) || '{}');
};

export const setJobWizardJobName = (jobName: string) => {
  sessionStorage.setItem(jobNameKey, jobName);
};

export const getJobWizardJobName = () => {
  return sessionStorage.getItem(jobNameKey) || undefined;
};
