/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ConfigProvider } from 'antd';
import { px2remTransformer, StyleProvider } from '@ant-design/cssinjs';
import { antdComponentConfig, antdTokens } from '@/theme';

export type ThemeProps = { children: React.ReactNode };

const px2rem = px2remTransformer({
  rootValue: 16,
});

export function Theme({ children }: ThemeProps) {
  return (
    <ConfigProvider
      theme={{
        token: antdTokens,
        components: antdComponentConfig,
      }}
    >
      <StyleProvider transformers={[px2rem]}>{children}</StyleProvider>
    </ConfigProvider>
  );
}
