/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import dayjs from 'dayjs';
import { ColumnType } from 'antd/es/table';
import { UseQueryResult } from '@tanstack/react-query';
import { Job, JobNode, JobNodeFilters, JobStatus, PaginatedJobNodesResponse } from '@/client';
import { Card } from '@/primitives/Card';
import { Title } from '@/primitives/Title';
import { MimicTranslationFunction, useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { PaginatedTable } from '../PaginatedTable';
import { Text } from '@/primitives/Text';
import { Link } from '@/primitives/Link';
import { Tooltip } from '@/primitives/Tooltip';
import { OsIcon } from '../Icons';
import { JobNodeStatus } from './JobNodeStatus';
import { UpdateParams } from '@/utils/params';

export type JobDetailsConfigProps = {
  tenantID: string;
  job: Job;
  jobNodesQuery: UseQueryResult<PaginatedJobNodesResponse, Error>;
  onParamsChange: UpdateParams<JobNode, JobNodeFilters>;
};

export function JobDetailsTable({ tenantID, job, jobNodesQuery, onParamsChange }: JobDetailsConfigProps) {
  const { t } = useMimicTranslation('jobs');
  const columns = getJobColumns(tenantID, job, t);

  return (
    <Card padding="md" gap="sm">
      <Title level={3} style={{ fontSize: '17px', fontWeight: '700' }}>
        {t('jobDetailsTableTitle')}
      </Title>
      <PaginatedTable
        columns={columns}
        paginatedResource={jobNodesQuery}
        rowKey={(jobNode) => jobNode.id}
        onParamsChange={onParamsChange}
      />
    </Card>
  );
}

function getJobColumns(tenantID: string, job: Job, t: MimicTranslationFunction<'jobs'>) {
  const columns: ColumnType<JobNode>[] = [
    {
      title: <span data-testid="hostName">{t('jobNodes.hostName')}</span>,
      dataIndex: 'hostname',
      sorter: true,
      render: (hostname: string, jobNode: JobNode) => {
        return (
          <Link
            to={`/tenants/${tenantID}/nodes/${jobNode.nodeId}`}
            style={{ fontSize: 'inherit', fontFamily: 'DM Mono' }}
            dataTestId="node-link"
          >
            <Text type="link" size="md">
              {hostname}
            </Text>
          </Link>
        );
      },
    },
    {
      title: (
        <span data-testid="nodeVersion">
          {t('jobNodes.nodeVersion', { startDate: `${dayjs(job.dateCreated).format('D MMM YYYY')}` })}
        </span>
      ),
      dataIndex: 'nodeVersion',
      sorter: true,
      render: (nodeVersion: string) => {
        return (
          <Text type="default" size="md">
            {nodeVersion}
          </Text>
        );
      },
    },
    {
      title: <span data-testid="os">{t('jobNodes.os')}</span>,
      dataIndex: 'operatingSystem',
      sorter: true,
      render: (os: JobNode['operatingSystem'], jobNode: JobNode) => {
        return (
          <Tooltip title={`${os} ${jobNode.operatingSystem}`} placement="right">
            <div data-testid="operating-system-icon">
              <OsIcon name={os} />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: <span data-testid="status">{t('jobNodes.status')}</span>,
      dataIndex: 'status',
      sorter: true,
      render: (status: JobStatus) => {
        return <JobNodeStatus status={status} />;
      },
    },
  ];

  return columns;
}
