/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { UseQueryResult } from '@tanstack/react-query';
import { Job, JobNode, JobNodeFilters, PaginatedJobNodesResponse } from '@/client';
import { Flex } from '@/primitives/Flex';
import { JobDetailsConfig } from './JobDetailsConfig';
import { JobDetailsHeader } from './JobDetailsHeader';
import { JobDetailsTable } from './JobDetailsTable';
import { UpdateParams } from '@/utils/params';

export type JobDetailsProps = {
  tenantID: string;
  jobNodesQuery: UseQueryResult<PaginatedJobNodesResponse, Error>;
  job: Job;
  onJobNodesParamsChange: UpdateParams<JobNode, JobNodeFilters>;
};

export function JobDetails({ tenantID, jobNodesQuery, job, onJobNodesParamsChange }: JobDetailsProps) {
  return (
    <Flex vertical>
      <JobDetailsHeader job={job} />
      <JobDetailsConfig job={job} />
      <JobDetailsTable
        tenantID={tenantID}
        jobNodesQuery={jobNodesQuery}
        job={job}
        onParamsChange={onJobNodesParamsChange}
      />
    </Flex>
  );
}
