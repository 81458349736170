/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export function useGetConfigRevisionQuery(tenantId: string, configId: string, configRevisionNumber: number) {
  return useQuery({
    queryKey: ['config-revisions', configId, configRevisionNumber],
    queryFn: async () => {
      return mgmtPlaneAPIClient.nodeConfigs.getConfigRevision({
        tenantId,
        configId,
        configRevisionNumber,
      });
    },
  });
}
