/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Container } from '@/primitives/Container';
import { Title, TitleProps } from '@/primitives/Title';
import { Text } from '@/primitives/Text';
import { Space } from '@/primitives/Space';
import { Flex } from '@/primitives/Flex';

export type PageHeaderProps = {
  title: React.ReactNode;
  icon?: React.ReactNode;
  text?: React.ReactNode;
  children?: React.ReactNode;
  level?: TitleProps['level'];
  fontWeight?: string;
};

function genTitleContainer(
  title: React.ReactNode,
  text: React.ReactNode,
  icon: React.ReactNode,
  fontWeight: string = '600',
  level: TitleProps['level'] = 1,
) {
  const subtext = text ? <Text data-testid="page-header-subtext">{text}</Text> : null;
  if (icon) {
    return (
      <Container gap="xs">
        <Title level={level} style={{ fontWeight }} data-testid="page-header-icon-title">
          <Space>
            {icon}
            {title}
          </Space>
        </Title>
        {subtext}
      </Container>
    );
  }
  return (
    <Container gap="xs">
      <Title level={level} data-testid="page-header-title">
        {title}
      </Title>
      {subtext}
    </Container>
  );
}

export function PageHeader({ title, text, icon, level, fontWeight, children }: PageHeaderProps) {
  const ch = children ? (
    <Flex vertical justify="center">
      {children}
    </Flex>
  ) : null;
  return (
    <div style={{ width: '100%' }} data-testid="page-header">
      <Flex vertical>
        <Flex justify="space-between">
          {genTitleContainer(title, text, icon, fontWeight, level)}
          {ch}
        </Flex>
      </Flex>
    </div>
  );
}
