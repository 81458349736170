/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Menu as AntDMenu, MenuProps as AntDMenuProps } from 'antd';
import { CSSProperties } from 'react';

type MenuItem = Required<AntDMenuProps>['items'][number];

const style: CSSProperties = {
  borderInlineEnd: 'none',
};

export type MenuDefProps = {
  items: MenuItem[];
  mode?: Required<AntDMenuProps>['mode'];
  defaultSelectedKeys: string[];
  defaultOpenKeys?: string[];
};

export function Menu({ items, mode, defaultSelectedKeys, defaultOpenKeys }: MenuDefProps) {
  return (
    <AntDMenu
      triggerSubMenuAction="click"
      style={style}
      mode={mode || 'inline'}
      items={items}
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
    />
  );
}
