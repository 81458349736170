/*
 * Copyright Mimic Networks, Inc. 2024.
 */
import { Button } from '@/primitives/Button';
import { Tag } from '@/primitives/Tag';
import { Tooltip } from '@/primitives/Tooltip';

export type TagsListProps = {
  tags: string[];
};

function ExtraTagsTooltip({ tags }: { tags: string[] }) {
  if (tags?.length < 3) return null;
  return (
    <ul data-testid="extra-tag" style={{ padding: '0 24px' }}>
      {tags.map((t, i) => {
        if (i < 3) return null;
        return <li key={t}>{t}</li>;
      })}
    </ul>
  );
}

export function TagsList({ tags }: TagsListProps) {
  if (!tags?.length) return '-';

  const hasTooManyTags = tags.length > 3 === true;

  return (
    <div style={{ maxWidth: '220px' }}>
      {tags.map((t, i) => {
        if (i > 2) return null;
        return (
          <div key={t} style={{ padding: '2px 5px', display: 'inline-block' }}>
            <Tag>{t}</Tag>
          </div>
        );
      })}
      {hasTooManyTags && (
        <Tooltip title={<ExtraTagsTooltip tags={tags} />} placement="right">
          <div data-testid="remaining-tags" style={{ display: 'inline-block' }}>
            <Button style={{ paddingLeft: 2, paddingRight: 0 }} type="link">
              +{tags.length - 3} more…
            </Button>
          </div>
        </Tooltip>
      )}
    </div>
  );
}
