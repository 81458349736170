/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Dropdown as AntDDropdown, DropDownProps as AntDDropdownProps } from 'antd';
import { ReactNode } from 'react';

export type { MenuProps } from 'antd';

export type DropDownProps = {
  customDialog?: () => ReactNode;
  children?: ReactNode;
  menu?: AntDDropdownProps['menu'];
  open?: AntDDropdownProps['open'];
  onOpenChange?: AntDDropdownProps['onOpenChange'];
  dataTestId?: string;
};

export function Dropdown({ menu, open, customDialog, onOpenChange, children, dataTestId }: DropDownProps) {
  return (
    <AntDDropdown
      destroyPopupOnHide
      menu={menu}
      open={open}
      trigger={['click']}
      dropdownRender={customDialog}
      onOpenChange={onOpenChange}
    >
      {/* This div nesting fixes react error about "findDOMNode deprecated in StrictMode" */}
      <div data-testid={dataTestId}>{children}</div>
    </AntDDropdown>
  );
}
