/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { UseQueryResult } from '@tanstack/react-query';
import { Avatar } from 'antd';
import dayjs from 'dayjs';
import { ConfigRevision, ConfigRevisionFilters, PaginatedConfigRevisionResponse } from '@/client';
import { Flex } from '@/primitives/Flex';
import { Link } from '@/primitives/Link';
import { Pagination } from '@/primitives/Pagination';
import { Skeleton } from '@/primitives/Skeleton';
import { Text } from '@/primitives/Text';
import { Timeline, TimelineProps } from '@/primitives/Timeline';
import { tokens } from '@/theme';
import { UpdateParams } from '@/utils/params';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { NodeViewPageCard } from './Node/NodeViewPageCard';

export type RevisionHistoryProps = {
  configRevisionsQuery: UseQueryResult<PaginatedConfigRevisionResponse>;
  pathToRevision: (configRevision: ConfigRevision) => string;
  onParamsChange: UpdateParams<ConfigRevision, Partial<ConfigRevisionFilters>>;
};

export function RevisionHistory({ configRevisionsQuery, pathToRevision, onParamsChange }: RevisionHistoryProps) {
  const { t } = useMimicTranslation('nodeConfigurationDetail');

  if (configRevisionsQuery.isLoading) {
    return <Skeleton loading />;
  }

  const revisions = configRevisionsQuery.data?.data || [];

  const revisionDates = Array.from(
    new Set(revisions.map((revision) => dayjs(revision.createdAt).utc().format('D MMM YYYY'))),
  );

  const timelineItems: TimelineProps['items'] = [];
  revisionDates.forEach((date) => {
    timelineItems.push({
      color: 'black',
      children: (
        <Text type="subtle" data-testid="event-date" strong>
          {date}
        </Text>
      ),
      dot: (
        <div
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: tokens.color.components.timeline.timelineNeutralInverse,
          }}
        />
      ),
    });
    revisions
      .filter((revision) => dayjs(revision.createdAt).utc().format('D MMM YYYY') === date)
      .forEach((revision) => {
        timelineItems.push({
          color: 'black',
          children: <ConfigRevisionItem revision={revision} pathToRevision={pathToRevision} />,
          dot: (
            <div
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: 'transparent',
                border: `1px solid ${tokens.color.components.timeline.timelineNeutralInverse}`,
              }}
            />
          ),
        });
      });
  });

  const pagination = configRevisionsQuery.data?.meta.page;

  const onChange = (page: number, pageSize: number) => {
    onParamsChange({ filters: {}, paginationParams: { number: page, size: pageSize }, sort: [] });
  };

  return (
    <NodeViewPageCard title={t('revisionHistory.title')} dataTestId="revision-history">
      <Timeline items={timelineItems} />
      <Pagination
        current={pagination?.currentPage}
        pageSize={pagination?.pageSize}
        total={pagination?.totalItems}
        onChange={onChange}
      />
    </NodeViewPageCard>
  );
}

type ConfigRevisionItemProps = {
  revision: ConfigRevision;
  pathToRevision: (configRevision: ConfigRevision) => string;
};

function ConfigRevisionItem({ revision, pathToRevision }: ConfigRevisionItemProps) {
  return (
    <Flex vertical gap="xxs">
      <Flex>
        <Link to={pathToRevision(revision)} dataTestId="config-revision-description">
          <Text
            type="link"
            data-testid="revision-description"
          >{`#${revision.revisionNumber} · ${revision.description}`}</Text>
        </Link>
      </Flex>
      <Flex gap="xs" align="center">
        <Avatar
          size={20}
          icon={revision.createdBy.displayName[0]}
          style={{
            backgroundColor: 'rgb(25, 25, 25, 0.20)',
            color: 'rgb(25, 25, 25, 0.75)',
            fontSize: '0.625rem',
            fontWeight: 900,
          }}
          data-testid="avatar"
        />
        <Text type="subtle" size="sm" data-testid="assigned-by">
          {revision.createdBy.displayName}
        </Text>
        <Text
          type="subtle"
          size="sm"
          data-testid="date-time"
        >{`${dayjs(revision.createdAt).utc().format('HH:mm:ss')} UTC`}</Text>
      </Flex>
    </Flex>
  );
}
