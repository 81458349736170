/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { LinuxOutlined, WindowsFilled } from '@ant-design/icons';
import { Avatar, Form } from 'antd';
import { ConfigRevision, NodeConfig } from '@/client';
import { Col } from '@/primitives/Col';
import { Link } from '@/primitives/Link';
import { Row } from '@/primitives/Row';
import { Skeleton } from '@/primitives/Skeleton';
import { Text } from '@/primitives/Text';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { CardCollapse } from './CardCollapse';
import { IncomingData } from './Node/IncomingData';
import { NodeViewPageCard } from './Node/NodeViewPageCard';
import { RelativeTime } from './RelativeTime';

export type ConfigurationOverviewProps = {
  nodeConfig: NodeConfig;
  pathToRevision: (configRevision: ConfigRevision) => string;
};

export function ConfigurationOverview({ nodeConfig, pathToRevision }: ConfigurationOverviewProps) {
  const { t } = useMimicTranslation('nodeConfigurationDetail');
  const operatingSystem =
    nodeConfig.os === 'windows' ? (
      <div style={{ display: 'flex', gap: '8px' }} data-testid="os">
        <WindowsFilled style={{ color: 'rgb(25, 25, 25, 0.79)' }} />
        <Text type="subtle">{t('ConfigurationOverview.windows')}</Text>
      </div>
    ) : (
      <div style={{ display: 'flex', gap: '8px' }} data-testid="os">
        <LinuxOutlined style={{ color: 'rgb(25, 25, 25, 0.79)' }} />
        <Text type="subtle">{t('ConfigurationOverview.linux')}</Text>
      </div>
    );

  return (
    <CardCollapse>
      <NodeViewPageCard title={t('ConfigurationOverview.title')} dataTestId="configuration-details">
        <Form layout="vertical">
          <IncomingData
            label={t('ConfigurationOverview.configurationId')}
            loading={false}
            text={nodeConfig.id}
            dataTestId="configuration-id"
          />
          <IncomingData label={t('ConfigurationOverview.os')} loading={false} marginBottom={0}>
            {operatingSystem}
          </IncomingData>
        </Form>
      </NodeViewPageCard>
      <NodeViewPageCard>
        <Form layout="vertical" style={{ marginTop: '1.5em' }}>
          <Row>
            <Col span={12}>
              <Form.Item
                label={
                  <Text type="subtle" size="md" strong>
                    {t('ConfigurationOverview.latestRevision')}
                  </Text>
                }
              >
                <Skeleton loading={false}>
                  <Link to={pathToRevision(nodeConfig.latestConfigRevision)} dataTestId="latest-revision">
                    {`#${nodeConfig.latestConfigRevision.revisionNumber} · ${nodeConfig.latestConfigRevision.description}`}
                  </Link>
                </Skeleton>
              </Form.Item>
            </Col>
            <Col span={12}>
              <IncomingData label={t('ConfigurationOverview.lastUpdated')} loading={false} marginBottom={0}>
                <div style={{ display: 'flex', gap: '6px' }} data-testid="last-updated">
                  <RelativeTime date={nodeConfig.latestConfigRevision.createdAt} />
                  <Text>UTC</Text>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                  <Avatar
                    size={20}
                    icon={nodeConfig.latestConfigRevision.createdBy.displayName[0]}
                    style={{
                      backgroundColor: 'rgb(25, 25, 25, 0.20)',
                      color: 'rgb(25, 25, 25, 0.75)',
                      fontSize: '0.625rem',
                      fontWeight: 900,
                    }}
                    data-testid="avatar"
                  />
                  <Text data-testid="assigned-by">{nodeConfig.latestConfigRevision.createdBy.displayName}</Text>
                </div>
              </IncomingData>
            </Col>
          </Row>
        </Form>
      </NodeViewPageCard>
    </CardCollapse>
  );
}
