/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { DownOutlined, InboxOutlined } from '@ant-design/icons';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { Tag } from '@/client';
import { Button } from '@/primitives/Button';
import { Dropdown, MenuProps } from '@/primitives/Dropdown';
import { Space } from '@/primitives/Space';

enum ActionType {
  DELETE = 'delete',
}

export type TagActionDropdownParams = {
  tag: Tag;
  onDeleteTag: (tag: Tag) => void;
};

export function TagActionDropdown({ tag, onDeleteTag }: TagActionDropdownParams) {
  const { t } = useMimicTranslation('tags');
  const handleActionClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case ActionType.DELETE:
        onDeleteTag(tag);
        break;
      default:
        break;
    }
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <Space>
          <InboxOutlined />
          {t('actions.deleteTag')}
        </Space>
      ),
      danger: true,
      key: ActionType.DELETE,
    },
  ];

  const menu = {
    items,
    onClick: handleActionClick,
  };

  return (
    <Dropdown menu={menu}>
      <Button type="default" dataTestId="tag-actions">
        <DownOutlined />
      </Button>
    </Dropdown>
  );
}
