/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfigureNodesJobWizardFooter } from './ConfigureNodesJobWizardFooter';
import { Flex } from '@/primitives/Flex';
import { getJobWizardSelectedConfigRevisionInfo } from '@/state/jobWizardStore';
import { PaginatedTableProps } from '../PaginatedTable';
import { NodeConfig, NodeConfigFilters, NodeConfigSortableFields, NodeOperatingSystem } from '@/client';
import { PaginationParams } from '@/client/mgmtPlane';
import { useNodeConfigsQuery } from '@/utils/hooks/useNodeConfigsQuery';
import { fromSorter } from '@/utils/sort';
import { ConfigureNodesJobWizardSelectConfigRevisionSideBar } from './ConfigureNodesJobWizardSelectConfigRevisionSideBar';
import { ConfigureNodesJobWizardSelectConfigRevisionMain } from './ConfigureNodesJobWizardSelectConfigRevisionMain';
import { ConfigureNodesJobWizardSelectConfigRevisionCode } from './ConfigureNodesJobWizardSelectConfigRevisionCode';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { useNodesQuery } from '@/utils/hooks/useNodesQuery';
import { useGetConfigRevisionQuery } from '@/utils/hooks/useGetConfigRevisionQuery';

export type ConfigureNodesJobWizardSelectConfigRevisionProps = {
  tenantID: string;
  selectedNodes: { id: string; operatingSystem: NodeOperatingSystem }[];
};

export function ConfigureNodesJobWizardSelectConfigRevision({
  tenantID,
  selectedNodes,
}: ConfigureNodesJobWizardSelectConfigRevisionProps) {
  const { t } = useMimicTranslation('configureNodesJobWizard');
  const [nodeConfigFilters, setNodeConfigFilters] = useState<Partial<NodeConfigFilters>>({
    os: selectedNodes.at(0)?.operatingSystem,
  });
  const [nodeConfigSort, setNodeConfigSort] = useState<NodeConfigSortableFields>([]);
  const [nodeConfigPage, setNodeConfigPage] = useState<PaginationParams>({ number: 1, size: 10 });
  const [showConfigRevisionCode, setShowConfigRevisionCode] = useState(false);
  const [error, setError] = useState(false);
  const [errorMesssaage, setErrorMessage] = useState<string | undefined>(undefined);

  const selectedConfigRevisionInfo: { configId: string; revisionId: string; revisionNumber: number } =
    getJobWizardSelectedConfigRevisionInfo();

  const nodeConfigsQuery = useNodeConfigsQuery({
    tenantId: tenantID,
    filters: nodeConfigFilters,
    sort: nodeConfigSort,
    settings: nodeConfigPage,
  });

  const nodeIDs = selectedNodes.map((node) => node.id);
  const nodesQuery = useNodesQuery(tenantID, { id: nodeIDs.join(','), isOperating: undefined }, [], {
    number: 1,
    size: selectedNodes.length,
  });
  const nodeData = nodesQuery.data?.data;

  const configRevisionsQuery = useGetConfigRevisionQuery(
    tenantID,
    selectedConfigRevisionInfo.configId,
    selectedConfigRevisionInfo.revisionNumber,
  );
  const configRevision = configRevisionsQuery.data?.data;

  const navigate = useNavigate();

  const onPrevious = () => {
    navigate(`#select-nodes`);
  };

  const onNext = () => {
    if (!selectedConfigRevisionInfo?.configId) {
      setError(true);
      setErrorMessage(t('selectARevision'));
      return;
    }
    navigate(`#review-job`);
  };

  const updateQueryParams: PaginatedTableProps<NodeConfig>['onParamsChange'] = ({
    filters,
    sort,
    paginationParams,
  }) => {
    if (filters)
      setNodeConfigFilters({
        ...filters,
        os: selectedNodes.at(0)?.operatingSystem,
      });
    if (paginationParams) setNodeConfigPage(paginationParams);
    if (sort) {
      const sortFields = fromSorter<NodeConfigSortableFields, NodeConfig>(sort);
      setNodeConfigSort(sortFields);
    }
  };

  return (
    <>
      <div className="configure-nodes-job-wizard-content">
        <div>
          <Flex>
            {!showConfigRevisionCode && (
              <ConfigureNodesJobWizardSelectConfigRevisionSideBar tenantID={tenantID} selectedNodes={nodeData} />
            )}
            <ConfigureNodesJobWizardSelectConfigRevisionMain
              tenantID={tenantID}
              selectedNodes={selectedNodes}
              nodeConfigsQuery={nodeConfigsQuery}
              updateQueryParams={updateQueryParams}
              selectedConfigRevisionInfo={selectedConfigRevisionInfo}
              setShowConfigCode={setShowConfigRevisionCode}
              setError={setError}
            />
            {showConfigRevisionCode && (
              <ConfigureNodesJobWizardSelectConfigRevisionCode
                configRevision={configRevision}
                setShowConfigCode={setShowConfigRevisionCode}
              />
            )}
          </Flex>
        </div>
      </div>
      <ConfigureNodesJobWizardFooter
        onPrevious={onPrevious}
        onNext={onNext}
        onNextLabel={t('reviewJob')}
        onNextIcon={null}
        error={error}
        errorMessage={errorMesssaage}
      />
    </>
  );
}
