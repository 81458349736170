/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { BadgeProps } from 'antd';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { TextBadge } from '../TextBadge';

export type OperationalStateProps = {
  state: 'active' | 'idle';
};

type BadgeType = Record<OperationalStateProps['state'], BadgeProps['status']>;

const badgeType: BadgeType = {
  active: 'success',
  idle: 'default',
};

export function OperationalState({ state: status }: OperationalStateProps) {
  const { t } = useMimicTranslation('node');
  const badgeStatus = badgeType[status];

  return <TextBadge status={badgeStatus}>{t(status)}</TextBadge>;
}
