/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { CopyOutlined, SyncOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { MouseEventHandler, useState } from 'react';
import { Button } from '@/primitives/Button';
import { Input } from '@/primitives/Input';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';

export type GenerateTokenProps = {
  /**
   * The generated token to show.
   */
  token: string;
  /**
   * Generate token action.
   */
  generate: () => Promise<string | undefined>;
  /**
   * Copy token action.
   */
  copy: (token: string) => void;
  /**
   * Trigger token update internally. Used to clear token value.
   */
  setToken: (token: string) => void;
};

export function GenerateToken({ generate, copy, setToken, token }: GenerateTokenProps) {
  const { t: globalT } = useMimicTranslation('global');
  const { t } = useMimicTranslation('cliSetup');
  const [loading, setLoading] = useState(false);

  const generateToken = async () => {
    setLoading(true);
    const newToken = await generate();
    setToken(newToken || '');
    setLoading(false);
  };

  const regenerate: MouseEventHandler = async (event) => {
    event.stopPropagation();
    setToken('');
    await generateToken();
  };

  if (token === '') {
    const buttonText = loading ? globalT('loading') : t('auth.generate.button');
    return (
      <Button type="default" onClick={generateToken} loading={loading} dataTestId="generate-button">
        {buttonText}
      </Button>
    );
  }

  return (
    <Space.Compact>
      <Input readOnly value={token} dataTestId="token-field" />
      <Button type="default" onClick={() => copy(token)} dataTestId="copy-button">
        <CopyOutlined /> {globalT('copy')}
      </Button>
      <Button type="link" onClick={regenerate} dataTestId="regenerate-button">
        <SyncOutlined /> {globalT('regenerate')}
      </Button>
    </Space.Compact>
  );
}
