/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Typography } from 'antd';
import { LinkProps as AndDLinkProps } from 'antd/es/typography/Link';
import { CSSProperties, MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';

const { Link: AntDLink } = Typography;

export type LinkProps = {
  style?: CSSProperties;
  children?: React.ReactNode;
  to?: string;
  href?: AndDLinkProps['href'];
  onClick?: MouseEventHandler<HTMLElement>;
  dataTestId?: string;
};

export function Link({ to, href, style, onClick, children, dataTestId }: LinkProps) {
  const navigate = useNavigate();

  const handleClick: MouseEventHandler<HTMLElement> = (e) => {
    if (href) return;
    if (onClick) onClick(e);
    if (to) {
      e.preventDefault();
      navigate(to);
    }
  };

  return (
    <AntDLink href={to || href} style={style} onClick={handleClick} data-testid={dataTestId}>
      {children}
    </AntDLink>
  );
}
