/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form, FormInstance, FormProps } from 'antd';
import { ReactNode } from 'react';
import { ValidationError } from '../Forms/ValidationError';
import { Tag } from '@/client';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { TagIcon } from '../Icons';
import { Input } from '@/primitives/Input';
import { Container } from '@/primitives/Container';

export type TagFormProps = {
  tag?: Tag;
  loading: boolean;
  form: FormInstance<TagFormFields>;
  onFinish?: FormProps['onFinish'];
  okButton?: ReactNode;
  horizontal?: boolean;
};

export type TagFormFields = {
  name: string;
};

export function TagForm({ tag, loading, form, onFinish, okButton, horizontal }: TagFormProps) {
  const { t } = useMimicTranslation('tags');
  const maxLength = 20;

  const buttonFormItem = !okButton ? undefined : (
    <Form.Item style={{ marginInlineEnd: 0, margin: 0 }}>{okButton}</Form.Item>
  );
  return (
    <Form
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{ name: tag?.name }}
      layout="vertical"
      style={{ width: '100%' }}
    >
      <Container horizontal={horizontal} gap="xs" style={{ width: '100%' }}>
        <Form.Item<TagFormFields>
          status="error"
          name="name"
          style={{ width: '100%', marginBottom: 0 }}
          rules={[
            { required: true, message: <ValidationError>{t('tagForm.nameRequired')}</ValidationError> },
            {
              max: maxLength,
              message: <ValidationError>{t('tagForm.nameMaxLength', { maxLength })}</ValidationError>,
            },
          ]}
        >
          <Input
            disabled={loading}
            autoFocus
            prefix={<TagIcon />}
            placeholder={t('addTag.namePlaceholder')}
            onChange={(event) => {
              const tagName = event.target.value.split(' ').join('-').trim();
              form.setFieldValue('name', tagName);
            }}
            allowClear
            dataTestId="tag-name-input"
            count={{
              max: maxLength,
            }}
          />
        </Form.Item>
        {buttonFormItem}
      </Container>
    </Form>
  );
}
