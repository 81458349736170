/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Steps as AntdSteps } from 'antd';

export type StepsProps = {
  steps: string[];
  step: number;
};

export function Steps({ steps, step }: StepsProps) {
  return <AntdSteps current={step} items={steps.map((title) => ({ title }))} />;
}
