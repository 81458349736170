import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from '@/primitives/Button';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import './ConfigWizardOverlayFooter.css';

export type ConfigWizardOverlayFooterProps = {
  onCancel: () => void;
  onPrevious?: () => void;
  onNext: () => void;
  onNextDisabled: boolean;
  onNextLabel: string;
  onNextIcon: React.ReactNode;
};

export function ConfigWizardOverlayFooter({
  onCancel,
  onPrevious,
  onNext,
  onNextDisabled,
  onNextLabel,
  onNextIcon,
}: ConfigWizardOverlayFooterProps) {
  const { t } = useMimicTranslation('configWizardOverlay');

  const leftButton = onPrevious ? <PreviousButton onPrevious={onPrevious} /> : null;

  return (
    <div className="config-wizard-overlay-footer">
      <div className="left-buttons">{leftButton}</div>
      <div className="right-buttons">
        <Button dataTestId="cancel-config-wizard-overlay" type="link" onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button dataTestId="next-config-wizard-overlay" type="primary" onClick={onNext} disabled={onNextDisabled}>
          {onNextLabel}
          {onNextIcon || null}
        </Button>
      </div>
    </div>
  );
}

function PreviousButton({ onPrevious }: Required<Pick<ConfigWizardOverlayFooterProps, 'onPrevious'>>) {
  const { t } = useMimicTranslation('configWizardOverlay');
  return (
    <Button
      icon={<ArrowLeftOutlined />}
      dataTestId="previous-config-overlay"
      type="default"
      onClick={() => onPrevious()}
    >
      {t('previous')}
    </Button>
  );
}
