import { Radio as AntDRadio } from 'antd';

export type RadioProps = {
  checked: boolean;
  onClick?: () => void;
  dataTestId?: string;
};

export function Radio({ checked, onClick, dataTestId }: RadioProps) {
  return <AntDRadio checked={checked} onClick={onClick} data-testid={dataTestId} />;
}
