/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form, Modal } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PatchUser, UserResponse } from '@/client';
import { UpdateMemberForm } from '@/components/Members/UpdateMemberForm';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { useMessage } from '@/primitives/message';
import { useUpdateUserMutation } from '@/utils/hooks/useUpdateUserMutation';
import { getUserOptions } from '@/utils/hooks/getUserOptions';
import { StatusText } from '@/components/StatusText';

export function UpdateMemberModal() {
  const { t } = useMimicTranslation('members');
  const navigate = useNavigate();
  const { userID } = useParams();
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState<'unconfirmed' | 'pending' | 'confirmed'>('unconfirmed');
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const userOptions = getUserOptions(tenantID!, userID!);
  const [form] = Form.useForm<PatchUser>();
  const [message] = useMessage();

  const closeModal = () => {
    setOpen(false);
  };

  const afterClose = () => {
    navigate(-1);
  };

  const { mutate } = useUpdateUserMutation(tenantID!, {
    onSuccess: ({ data: userUpdated }: UserResponse) => {
      setStatus('confirmed');
      message.success(
        t('feedback.userUpdated', { user: `${userUpdated.firstName} ${userUpdated.lastName} ${userUpdated.email}` }),
      );
      closeModal();
    },
    onError: (_, createUserDto) => {
      message.error(
        t('feedback.errorOnUpdate', {
          user: `${createUserDto.firstName} ${createUserDto.lastName} ${createUserDto.email}`,
        }),
      );
    },
  });

  async function validateAndSubmit() {
    try {
      setStatus('pending');
      const userParams = await form.validateFields();
      mutate({ ...userParams, id: userID! });
    } catch (error) {
      setStatus('unconfirmed');
    }
  }

  return (
    <Modal
      title={t('update.title')}
      centered
      afterClose={afterClose}
      open={open}
      onOk={() => validateAndSubmit()}
      onCancel={closeModal}
      confirmLoading={status === 'pending'}
      okButtonProps={{ disabled: status === 'pending' }}
      cancelButtonProps={{ disabled: status === 'pending' }}
      okText={
        <StatusText
          status={status}
          text={{
            unconfirmed: t('update.buttonTextUnconfirmed'),
            pending: t('update.buttonTextPending'),
            confirmed: t('update.buttonTextConfirmed'),
          }}
        />
      }
    >
      <UpdateMemberForm form={form} userOptions={userOptions} />
    </Modal>
  );
}
