/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Pagination } from '@/client';
import { tokens } from '@/theme';
import { paginationRange } from '@/utils/pagination';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { ErrorIcon, WarningIcon } from '../Icons';

export type PaginationInfoParams = {
  pagination?: Pagination;
  primaryAction?: React.ReactNode;
  selectedIds?: string[];
  selectionWarning?: string;
  selectionError?: string;
};

const defaultStyle = {
  fontSize: '0.875rem',
  minHeight: '52px',
  padding: '0 16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: tokens.color.surface.surfaceHigh,
  // width: '100%',
  border: '1px solid rgba(25, 25, 25, 0.06)',
  borderBottom: 'none',
};

const warningStyle = {
  ...defaultStyle,
  backgroundColor: 'rgba(250, 246, 207, 0.7)',
  border: '1px solid rgba(250, 200, 61, 0.3)',
  borderBottomStyle: 'none',
};

const errorStyle = {
  ...defaultStyle,
  backgroundColor: 'rgba(248, 233, 229, 0.7)',
  border: '1px solid rgba(248, 233, 229, 1)',
  borderBottom: 'none !important',
  borderBottomStyle: 'none',
};

export function PaginationInfo({
  pagination,
  primaryAction,
  selectedIds,
  selectionWarning,
  selectionError,
}: PaginationInfoParams) {
  const { t } = useMimicTranslation('pagination');

  if (!pagination) {
    return null;
  }

  const { start, end, total } = paginationRange(pagination);
  const leftLabel = selectedIds?.length
    ? t('selectedItems', { selected: selectedIds.length })
    : t('summary', { start, end, total });

  // define style based on selectionWarning or selectionError
  let style = defaultStyle;
  if (selectionWarning) {
    style = warningStyle;
  }
  if (selectionError) {
    style = errorStyle;
  }

  return (
    <div style={style}>
      <div style={{ display: 'flex', gap: 16 }}>
        <div style={{ fontWeight: '600' }}>{leftLabel}</div>
        {selectionWarning && !selectionError && (
          <div style={{ display: 'flex', gap: 4 }}>
            <WarningIcon />
            {selectionWarning}
          </div>
        )}
        {selectionError && (
          <div style={{ display: 'flex', gap: 4, color: 'rgba(161, 53, 23, 1)' }}>
            <ErrorIcon />
            {selectionError}
          </div>
        )}
      </div>
      {primaryAction}
    </div>
  );
}
