/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/primitives/Button';
import { tokens } from '@/theme';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';

export type BackButtonProps = {
  to: string;
};

export function BackButton({ to }: BackButtonProps) {
  const navigate = useNavigate();
  const { t } = useMimicTranslation('global');

  const handleClick = () => {
    navigate(to);
  };

  return (
    <Button type="link" onClick={handleClick} style={{ padding: 0 }} dataTestId="back-button">
      <span style={{ fontWeight: 'bold', color: tokens.color.primary.primaryBlack }}>
        <ArrowLeftOutlined style={{ color: tokens.color.primary.primaryBlack }} /> {t('back')}
      </span>
    </Button>
  );
}
