/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { en } from './en';

export const pt: typeof en = {
  translation: {
    global: {
      hello: 'Olá',
      ops: 'Ops, algo deu errado',
      dashboard: 'Painel',
      signIn: 'Entrar',
      signOut: 'Sair',
      copyright: 'Direitos Autorais',
      next: 'Próximo',
      back: 'Voltar',
      finish: 'Finalizar',
      copy: 'Copiar',
      copied: 'Copiado',
      example: 'Exemplo',
      loading: 'Carregando',
      regenerate: 'Regenerar',
      notAvailable: 'Não disponível',
      apiRequestFailedTitle: 'Falha na solicitação da API',
      apiRequestFailedDescription: '{{method}} request to {{url}} failed: {{error}}',
      forms: {
        required: 'Isso é necessário',
        invalid: 'Inválido',
      },
    },
    assignConfigOverlay: {
      title: 'Selecione uma configuração para atribuir ao nó',
      cancel: 'Cancelar',
      assign: 'Atribuir',
    },
    configWizardOverlay: {
      selectConfiguration: 'Selecione a configuração',
      selectRevision: 'Selecione a revisão',
      node: 'Nó',
      configuration: 'Configuração',
      configurations: 'Configurações',
      revision: 'Revisão',
      revisions: 'Revisões',
      assign: 'Atribuir',
      cancel: 'Cancelar',
      change: 'Alterar',
      next: 'Próximo',
      previous: 'Anterior',
      reviewConfigurations: 'Revise as configurações',
    },
    footer: {
      copyright: '© 2024 Mimic Networks, Inc.',
      contactUs: 'Preciso de ajuda? Contate-nos',
    },
    jobs: {
      listTitle: 'Tarefas',
      end: 'Fim',
      jobDetailsTitle: 'Tarefa',
      revision: 'Revisão',
      start: 'Início',
      targetConfig: 'Configuração almejada',
      viewJob: 'Ver tarefa',
      jobDetailsTableTitle: 'Nós',
      jobNodes: {
        hostName: 'Nome do anfitrião',
        nodeVersion: 'Versão do nó ({{startDate}})',
        os: 'OS',
        status: 'Status',
        processing: 'Processamento',
        configured: 'Configurado',
        rejected: 'O nó rejeitou a configuração',
        warning: 'Configurado com aviso',
      },
      table: {
        jobName: 'Nome',
        targetConfig: 'Configuração',
        os: 'SO',
        timedout: 'Tempo esgotado',
        failed: 'Falhas',
        warning: 'Avisos',
        success: 'Sucesso',
        started: 'Iniciado',
        startedBy: 'Por',
      },
    },
    configureNodesJobWizard: {
      configureNodes: 'Configurar nós',
      selectNodes: 'Selecione nós',
      selectConfig: 'Selecione a configuração',
      reviewJob: 'Revisar tarefa',
      startJob: 'Iniciar tarefa',
      previous: 'Anterior',
      nodes: 'Nós',
      selectANode: 'Selecione pelo menos um nó',
      selectARevision: 'Selecione uma revisão de uma configuração',
      nodesSelected: 'nós selecionados',
      configName: 'Nome da configuração',
      assignedTo: 'Atribuído a',
      lastRevised: 'Última revisão',
      nodesLower: 'nós',
      rev: 'Revisão',
      configuringNodesCreates: 'Configurar nós cria um tarefa',
      jobName: 'Nome do tarefa',
      targetConfig: 'Configuração de destino',
      os: 'OS',
      tags: 'Tags',
      nodeVersion: 'Versão do nó',
      operationalState: 'Estado operacional',
      hostName: 'Nome do host',
      unableToStartJob: 'Erro inesperado ao tentar iniciar a tarefa',
      jobNameRequired: 'Por favor, informe um nome para a tarefa',
    },
    pagination: {
      summary: 'Mostrando {{start}}-{{end}} de {{total}} resultados',
      selectedItems: '{{count}} itens selecionados',
    },
    menu: {
      activity: 'Atividade',
      nodes: 'Nós',
      configurations: 'Configurações',
      notifications: 'Notificações',
      mimicCli: 'Mimic CLI',
      download: 'Download',
      authenticate: 'Autenticar',
      jobs: 'Tarefas',
      members: 'Membros',
      tags: 'Tag',
      logout: 'Sair',
      docs: 'Docs',
      swagger: 'Swagger',
      systemInfo: 'Sobre essa plataforma',
    },
    notifications: {
      title: 'Notificações',
      columns: {
        notification: 'Notificação',
        urgency: 'Urgência',
        timestamp: 'Tempo',
        channels: 'Canais',
      },
    },
    systemInfo: {
      title: 'Sobre essa plataforma',
      mgmtPlaneVersion: 'Versão do Management Plane',
    },
    tags: {
      title: 'Tag',
      subtitle: 'Gerencie suas tags.',
      newTag: 'Nova tag',
      tags: 'Tag',
      name: 'Nome',
      useCount: 'Usar contagem',
      confirmDelete: {
        title: 'Tem certeza de que deseja arquivar a tag “{{name}}”?',
        body1:
          'Esta tag está atualmente associada a nós {{useCount}}. Excluí-lo removerá a tag dos nós e a tag não estará mais disponível.',
        body2: 'Esta ação não pode ser revertida.',
        cancel: 'Não, cancelar',
        ok: 'Sim, excluir',
      },
      actions: {
        deleteTag: 'Excluir tag',
        editTagButton: 'Editar',
      },
      tagForm: {
        nameRequired: 'O nome é obrigatório.',
        nameMaxLength: 'O nome da tag não pode ter mais de {{maxLength}} caracteres.',
      },
      addTag: {
        namePlaceholder: 'Nomeie a nova tag...',
        submitButton: 'Adicionar',
      },
      editTag: {
        title: 'Editar nome da tag',
        namePlaceholder: 'Nomeie tag...',
        cancelButton: 'Cancelar',
        submitButton: 'Salvar',
      },
      newTagCreated: 'A nova tag "{{name}}" foi criada',
      tagUpdated: 'O nome da tag foi atualizado de "{{oldName}}" para "{{newName}}"',
      tagDeleted: 'A tag "{{name}}" foi excluída e removida de {{useCount}} nós associados.',
    },
    members: {
      title: 'Membros',
      subtitle: 'Gerenciar quem tem acesso a este espaço de trabalho',
      newMember: 'Novo membro',
      active: 'Ativo',
      removed: 'Removido',
      invite: {
        title: 'Convide para seu espaço de trabalho',
        firstNamePlaceholder: 'Introduza o primeiro nome...',
        lastNamePlaceholder: 'Insira o último nome...',
        emailPlaceholder: 'Insira o e-mail...',
        permissions: 'Convidar como',
        permissionsPlaceholder: 'Selecione permissões',
        roleMissingError: 'Selecione as permissões',
        enverValidEmail: 'Insira um email válido',
        required: 'Insira o seu sobrenome',
        buttonTextUnconfirmed: 'Adicionar membro',
        buttonTextPending: 'Adicionando...',
        buttonTextConfirmed: 'Adicionado!',
      },
      firstName: 'Primeiro Nome',
      lastName: 'Sobrenome',
      email: 'Email',
      roles: {
        superadmin: 'Super Admin',
        admin: 'Administrador',
        editor: 'Editor',
        viewer: 'Somente visualizar',
        adminMessage: 'Acesso total com todas as permissões',
        editorMessage: 'Acesso com permissões limitadas',
        viewerMessage: 'Acesso com permissões somente leitura',
      },
      actions: {
        makeAdmin: 'Faça de {{user}} um administrador',
        makeEditor: 'Faça de {{user}} um editor',
        makeViewer: 'Faça de {{user}} um visualizador',
        remove: 'Remove esta conta',
        restore: 'Restaurar',
        update: 'Atualizar informações do membro',
      },
      member: 'Membro',
      columns: {
        role: 'Permissões',
        dateCreated: 'Data Adicionado',
      },
      feedback: {
        userInvited: '{{user}} foi adicionado como membro em {{email}}',
        userUpdated: '{{user}} atualizado',
        errorOnInvite: 'Erro ao convidar {{user}}',
        errorOnUpdate: 'Erro ao atualizar {{user}}',
      },
      update: {
        title: 'Atualizar informações do membro',
        buttonTextUnconfirmed: 'Atualizar membro',
        buttonTextPending: 'Atualizando...',
        buttonTextConfirmed: 'Atualizado!',
      },
    },
    mimicSyntaxHighlighter: {
      copy: 'Copiar',
      copied: 'Copiado',
    },
    person: {
      name: 'Nome',
      email: 'Email',
      password: 'Senha',
    },
    logIn: {
      welcome: 'Bem-vindo à Mimic',
      instructions: 'Digite seu endereço de email comercial para entrar.',
      email: 'Digite endereço de email...',
      continue: 'Continuar',
      accountNotFound: 'Conta não encontrada',
      emailMismatch: 'Email informado não coincide com o email da conta',
      unexpectedError: 'Erro inesperado',
    },
    nodes: {
      title: 'Nós',
      failedToLoad: 'Falha ao carregar nós',
      configured: 'Configurados',
      pending: 'Pendentes',
      configureNodes: 'Configurar nós',
      table: {
        header: {
          dateCreated: 'Criado (UTC)',
          host_name: 'Nome do Host',
          lastSeen: 'Visto pela última vez (UTC)',
          nickname: 'Apelido',
          tags: 'Tags',
          status: 'Status',
          vigilance: 'Vigilância',
          os: 'OS',
          customer: 'Cliente',
          connectivity: 'Conectividade (UTC)',
          operationalState: 'Estado Operacional',
          configuration: 'Configuração',
          configurationUpdated: 'Atualizado por {{updatedBy}} em {{updatedAt}} (UTC)',
          ransomwareDetected: 'Ransomware Detectado',
          nodeVersion: 'Versão do nó',
          config: 'Configuração',
        },
        view_button: 'Visualizar',
        notAvailable: 'não disponível',
        assignConfiguration: 'Atribuir configuração',
        configurationInProgress: 'Configuração em andamento',
      },
      banner: {
        selected: 'selecionado',
        selectAll: 'Selecione tudo',
        deselectAll: 'Desmarcar tudo',
        differentOSWarning: 'Os nós têm sistemas operacionais diferentes',
        differentOSError: 'Os nós devem ter o mesmo sistema operacional para serem configurados juntos',
        configureNodes: 'Configurar nós',
      },
    },
    configRevisions: {
      title: 'Revisões',
      table: {
        revision: 'Revisão',
        author: 'Autor',
        id: 'ID',
        createdAt: 'Criado em (UTC)',
      },
    },
    nodeConfigurations: {
      title: 'Configurações',
      table: {
        header: {
          name: 'Nome',
          assigned: 'Atribuído a',
          revision: 'Última revisão',
          tags: 'Tags',
          os: 'OS',
          lastRevised: 'Ultima revisão (UTC)',
          id: 'ID',
        },
        nameNotAvailable: 'não disponível',
        nodeCount: '{{nodeCount}} nós',
        outOfDate: '{{nodeCount}} nós desatualizados',
      },
    },
    nodeConfigurationDetail: {
      title: 'Detalhes da configuração',
      notes: 'Notas',
      updatingNotesFailed: 'Falha ao atualizar notas',
      notesPlaceholder: 'Notas vão aqui',
      assignedNodes: {
        title: 'Nós atribuídos',
        assignConfigToNode: 'Atribuir configuração ao nó',
        table: {
          header: {
            nodes: 'Nós',
            tags: 'Tags',
            configVersion: 'Versão de configuração',
            dateConfigRevisionApplied: 'Ultima atualização (UTC)',
            assginedBy: 'Atribuído por',
          },
        },
        noNodesAssigned: 'Nenhum nó atribuído',
      },
      revisionHistory: {
        title: 'Histórico de Revisão',
      },
      revisionsTable: {
        revisionDesription: 'Descrição da revisão',
        author: 'Autor',
        created: 'Criado (UTC)',
      },
      ConfigurationOverview: {
        title: 'Visão geral da configuração',
        configurationId: 'ID de configuração',
        os: 'OS',
        latestRevision: 'Última revisão',
        lastUpdated: 'Ultima atualização',
        windows: 'Windows',
        linux: 'Linux',
      },
    },
    node: {
      overview: 'Visão Geral',
      node_description: 'Descrição',
      node_description_placeholder: 'A descrição do nó vai aqui...',
      addTag: 'Adicionar tag',
      server_configuration: 'Configuração do Servidor',
      ip_address: 'Endereço IPv4',
      os: 'OS',
      nodeConfiguration: 'Configuração do Nó',
      currentConfiguration: 'Configuração atual',
      lastUpdatedBy: 'Última atualização por',
      configuration: 'Configuração',
      revision: 'Revisão',
      selectNewConfig: 'Selecione nova configuração',
      selectNewRevision: 'Selecione nova revisão',
      notConfigured: 'Este nó não está configurado',
      assignConfiguration: 'Atribuir configuração',
      node_id: 'ID do Nó',
      node_version: 'Versão do nó',
      node_config_id: 'ID da Configuração do Nó',
      config_file: 'Arquivo de Configuração',
      config_file_description: 'Descrição do Arquivo de Configuração',
      node_state: 'Estado do Nó',
      vigilance: 'Vigilância',
      tags: 'Tags',
      credentials: 'Credenciais',
      registered: 'Registrado',
      heartbeats: 'Heartbeats',
      last_configuration_heartbeat: 'Última verificação de configuração',
      last_credentials_heartbeat: 'Última verificação de credenciais',
      unknown_heartbeat: 'Desconhecido',
      cert_expiration: 'Expiração do certificado',
      last_cert_rotation: 'Última rotação de certificado',
      last_seen: 'Última visualização',
      node_status: 'Status',
      node_mode: 'Modo',
      connectivity: 'Conectividade',
      activity: 'Atividade',
      operationalState: 'Estado operacional do nó',
      lifecycle_events: 'Eventos do Ciclo de Vida do Nó',
      events: 'Eventos',
      date: 'Data',
      hallmarks: 'Marcas',
      deflectionsActive: 'Ativo',
      deflectionsInactive: 'Inativo',
      feedback: {
        operationalStateChanged: 'Estado do nó {{nodeId}} está sendo alterado para {{state}}',
        errorFetchingConfigRevision: 'Erro ao carregar a revisão',
        assignNodeConfigError: 'Erro ao atribuir a configuração ao nó',
        nodeUpdated: 'Nó atualizado',
        updateFailed: 'Erro ao atualizar o nó',
      },
      detections: 'Detecções',
      encryptions: 'Criptografias',
      encryptions_present: 'Criptografias Presentes',
      deflections: 'Deflexões',
      active: 'Ativo',
      inactive: 'Intivo',
      idle: 'Inativo',
      back: 'Voltar para Nós',
      operationalStateChanging:
        'Processando sua solicitação para mudar o estado operacional do nó de {{currentState}} para {{requestedState}}',
      operationalStateChangeRequested: 'Solicitação de mudança de estado operacional',
      operationalStateChangeFailed: 'Falha na mudança de estado operacional',
      operationalStateChangeFailedDescription:
        'Ocorreu um erro ao tentar mudar o estado operacional do nó para {{requestedState}}. Por favor tente novamente. Se o erro persistir, contate o adminstrador do sistema.',
      connectivityState: {
        connected: 'Conectado',
        disconnected: 'Desconectado',
        degraded: 'Degradado',
        unknown: 'Desconhecido',
      },
      connectivityStateDesc: {
        connected: 'Nó conectado',
        disconnected: 'Nó desconectado',
        degraded: 'Nó degradado',
        unknown: 'Desconhecido',
      },
      hallmarksTable: {
        mimicID: 'Id da Marca',
        mimicDescription: 'Marca',
        occurrences: 'Ocorrências',
        firstSeen: 'Primeira Aparição (UTC)',
        lastSeen: 'Última Aparição (UTC)',
      },
      tagDropdown: {
        typeToAddTag: 'Para adicionar uma nova tag, comece a digitar',
        addTagOption: 'Adicione uma nova tag  "{{tagName}}"',
        showMore: 'Mostrar mais {{count}}...',
      },
      hostNameNotAvailable: 'não disponível',
      selectConfiguration: 'Selecione uma configuração',
      selectRevision: 'Selecione uma revisão',
      viewDetails: 'Ver detalhes',
      selectConfigRevisionConfirmation: {
        title: 'Selecionar revisão de configuração',
        description: 'Tem certeza que deseja alterar a revisão de configuração para este nó?',
        yes: 'Sim',
        no: 'Não',
      },
      status: {
        configuringTo: 'Configuring to',
        failedToConfigure: 'Failed to configure to',
        of: 'of',
        revision: 'revision',
      },
      eventsTable: {
        appliedConfig: 'Configurado com sucesso para',
        rejectedConfig: 'Falha ao configurar para',
        pendingConfig: 'Configuração pendente para',
        obtainedConfig: 'Alterando a configuração para',
      },
    },
    installer: {
      download_installer: 'Baixar o instalador',
      installer: 'instalador',
      done_button: 'Feito',
      steps: {
        default: {
          step1_pre_action: 'Baixe o',
          step1_post_action: '.',
          step2: 'Execute o instalador nos servidores que deseja proteger com um Nó da Mimic.',
          step3:
            'Uma vez instalado, a Mimic enviará telemetria contendo informações sobre o servidor e o nó será adicionado à sua conta.',
        },
      },
    },
    activity: {
      title: 'Atividade',
      noWorries: 'Nenhum nó com indícios',
      allClear: 'Tudo está certo, sem motivo para se preocupar',
      unexpectedError: 'Erro inesperado',
      unexpectedErrorDescription: 'Algo deu errado. Estamos trabalhando para corrigir.',
      lastUpdated: 'Última atualização',
      actions: {
        refresh: 'Atualizar',
      },
      table: {
        hostName: 'Nome do Host',
        tags: 'Tags',
        connectivity: 'Conectividade (UTC)',
        detection: 'Detecção',
        encryption: 'Encriptação',
        deflections: 'Deflexões',
        deflectionsActive: 'Ativo',
        deflectionsInactive: 'Inativo',
      },
      notAvailable: 'Não disponível',
    },
    cliSetup: {
      skipSetup: 'Pular Configuração',
      title: 'Configuração da Interface de Linha de Comando (CLI) da Mimic',
      failedCopy: 'Falha ao copiar o texto',
      failedToGenerate: 'Falha ao gerar o token',
      auth: {
        title: 'Autenticação da CLI com a Mimic',
        downloadLinkText: 'Clique aqui para baixar a CLI.',
        generate: {
          button: 'Gerar token de autenticação',
        },
        step1: {
          number: '1.',
          name: 'Verifique se a Mimic está instalada',
          instructions: 'No terminal, digite',
          command: 'mimic version',
          successPart1: 'Se um número de versão for retornado, por exemplo',
          successExample: 'Mimic 1.0.0',
          successPart2: ', vá para a etapa 2 para autenticar.',
          failurePart1: 'Se você vir qualquer outra coisa, por exemplo',
          failureExample: 'mimic: command not found',
          failurePart2: ', você precisa instalar a CLI.',
        },
        step2: {
          number: '2.',
          name: 'Autentique com a Mimic',
          instructions: 'No terminal, digite',
          command: 'mimic login',
          description:
            'Você será solicitado a inserir um token de autenticação. Para gerar um token, clique no botão abaixo.',
          example: {
            loginCommand: '$ mimic login\nEnter email: {{email}}\nEnter the authentication token: {{token}}',
          },
        },
        help: {
          description: 'Para ver todos os comandos disponíveis, digite',
          command: 'mimic -h',
        },
      },
      download: {
        title: 'Baixe a CLI do Mimic',
        subtitle:
          'Selecione uma opção abaixo para baixar e instalar o binário CLI apropriado para seu sistema operacional.',
        start: 'Iniciou o download do instalador {{osName}}.',
        after: {
          span1: 'Depois de instalar,',
          linkText: 'clique aqui para autenticar com Mimic',
        },
        linux: 'Linux',
        windows: 'Windows',
        mac: 'Mac',
      },
      step0: {
        title: 'Bem-vindo à Mimic!',
        body: 'Antes de continuar, sugerimos configurar sua Interface de Linha de Comando (CLI) da Mimic. Isso ajudará você a realizar tarefas comuns da plataforma a partir da linha de comando.',
        start: 'Iniciar Configuração da Mimic CLI',
      },
      step1: {
        stepNumber: 'Passo 1.',
        stepName: 'Baixe a CLI',
        body: 'Selecione uma opção abaixo para baixar e instalar a CLI apropriada para seu sistema operacional.',
        downloadLinux: 'Baixar para Linux',
        downloadWindows: 'Baixar para Windows',
      },
      step2: {
        stepNumber: 'Passo 2.',
        stepName: 'Verifique a instalação',
        body: 'No seu terminal, verifique se a Mimic CLI foi instalada digitando',
        command: 'Lorem ipsum',
        commandCopied: 'Comando copiado para a área de transferência',
        body2: 'Continue para a próxima etapa quando você vir',
        commandSuccess: 'Lorem ipsum',
      },
      step3: {
        stepNumber: 'Passo 3.',
        stepName: 'Autentique com o token',
        body: 'Gere e insira o token abaixo para autenticar com a Mimic.',
        tokenCopied: 'Token copiado para a área de transferência',
      },
      step4: {
        title: 'Parabéns, você configurou com sucesso a Mimic CLI!',
        body: 'Você pode acessar estas instruções novamente em',
        settingsCliSetup: 'Configurações > Mimic CLI',
        continue: 'Continuar para Mimic',
      },
    },
    error: {
      errorMesage: 'Um erro ocorreu',
      forbiddenError: 'Acesso negado',
      forbiddenErrorDescription: 'Você não tem permissão para acessar esta página.',
      unexpectedError: 'Erro inesperado',
      unexpectedErrorDescription: 'Algo deu errado. Estamos trabalhando para corrigir.',
      notFoundError: 'Página não encontrada',
      notFoundErrorDescription: 'Desculpe, essa página não existe.',
    },
    tenants: {
      errorFetchingTenants: 'Erro ao buscar instâncias',
    },
  },
};
