/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { CSSProperties } from 'react';
import { Container } from '@/primitives/Container';
import { TShirtSize, tokens } from '@/theme';

export type AsideProps = {
  children?: React.ReactNode;
  gap?: TShirtSize | 'none';
};

export function Aside({ children, gap }: AsideProps) {
  const containerStyle: CSSProperties = {
    borderLeftWidth: '1px',
    borderLeftColor: tokens.color.border.borderDefault,
    borderLeftStyle: 'solid',
    paddingLeft: tokens.size.aside.paddingLeft,
    paddingTop: tokens.size.aside.paddingTop,
    paddingBottom: tokens.size.aside.paddingBottom,
  };

  return (
    <Container gap={gap} style={containerStyle}>
      {children}
    </Container>
  );
}
