/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { CopyOutlined } from '@ant-design/icons';
import { MouseEventHandler } from 'react';
import { Space } from 'antd';
import { Button } from '@/primitives/Button';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { Code } from '@/primitives/Code';
import { FontSize } from '@/theme';

type CopyTextCallback = (text: string) => void;

export type GenerateTokenProps = {
  size?: FontSize;
  text: string;
  copy: CopyTextCallback;
};

export function CopyCode({ size, text, copy }: GenerateTokenProps) {
  const { t } = useMimicTranslation('global');
  const clickCopy: MouseEventHandler = () => {
    copy(text);
  };
  return (
    <Space.Compact size="small" style={{ fontSize: 'inherit' }}>
      <Code size={size} dataTestId="text-content">
        {text}
      </Code>
      <Button type="link" onClick={clickCopy} dataTestId="copy-button">
        <CopyOutlined /> {t('copy')}
      </Button>
    </Space.Compact>
  );
}
