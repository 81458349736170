/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { useMessage } from '@/primitives/message';
import { CLIDownload } from '@/components/CLISetup/CLIDownload';
import { ContentContainer } from '@/components/ContentContainer';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';
import { OsType } from '@/client';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';

export function CLIDownloadRoute() {
  const { t } = useMimicTranslation('cliSetup');
  const tenantId = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const [message] = useMessage();

  const onDownload = async (osType: OsType) => {
    // TODO: Give the user the ability to select the architecture type of the
    // CLI in addition to its operating system type.
    const { data: cliDownload } = await mgmtPlaneAPIClient.cli.cliDownload({
      tenantId: tenantId!,
      archType: 'x86_64',
      osType,
    });
    message.success(t('download.start', { osName: t(`download.${cliDownload.OsType}`) }));
    window.open(cliDownload.downloadUrl);
  };

  return (
    <ContentContainer>
      <CLIDownload onDownload={onDownload} tenantID={tenantId!} />
    </ContentContainer>
  );
}
