/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useMutation } from '@tanstack/react-query';
import { Job, JobResponse, UpdateJob } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type UpdateJobMutationConfig = {
  onSuccess?: (data: JobResponse, variables: UpdateJob) => void;
  onError?: (error: unknown, variables: UpdateJob) => void;
  onSettled?: (data: unknown, error: unknown, variables: unknown) => void;
};

export function useUpdateJobMutation(job: Job, config?: UpdateJobMutationConfig) {
  return useMutation<JobResponse, unknown, UpdateJob>({
    mutationFn: (updateJobPayload: UpdateJob) => {
      return mgmtPlaneAPIClient.jobs.updateJob({
        jobId: job.id,
        requestBody: updateJobPayload,
        tenantId: job.tenantId,
      });
    },
    onSuccess: config?.onSuccess,
    onError: config?.onError,
    onSettled: config?.onSettled,
  });
}
