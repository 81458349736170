/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { WarningFilled } from '@ant-design/icons';
import { tokens } from '@/theme';

export type WarnIconParams = {
  on?: 'toast';
};

const COLOR_MAP = {
  default: tokens.color.error.warning.warning,
  toast: tokens.color.components.message.warningOnToastMessageBg,
};

export function WarningIcon({ on }: WarnIconParams) {
  const color = COLOR_MAP[on || 'default'];
  return <WarningFilled style={{ color }} />;
}
