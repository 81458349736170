/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { queryOptions } from '@tanstack/react-query';
import { mgmtPlaneAPIClient, PaginationParams } from '@/client/mgmtPlane';
import { UserFilters, UserSortableFields } from '@/client';

export function getUserListOptions(
  tenantId: string,
  sort: UserSortableFields,
  filters: UserFilters,
  settings: PaginationParams,
) {
  return queryOptions({
    queryKey: ['users', tenantId, settings.number, settings.size, filters.removed] as (string | number | undefined)[],
    queryFn: () =>
      mgmtPlaneAPIClient.users.getUsers({ tenantId, page: settings.number, pageSize: settings.size, filters, sort }),
    enabled: Boolean(tenantId),
  });
}

export type GetUserListOptions = ReturnType<typeof getUserListOptions>;
