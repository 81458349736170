/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQuery } from '@tanstack/react-query';
import { SystemInfoResponseSchema } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export function useGetSystemInfo() {
  const { data, isFetching } = useQuery({
    queryKey: ['systemInfo'],
    queryFn: async function getSystemInfo(): Promise<SystemInfoResponseSchema> {
      return mgmtPlaneAPIClient.systemInfo.getSystemInfo();
    },
  });

  return { data, isFetching };
}
