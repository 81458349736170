/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { UseMutationOptions } from 'node_modules/@tanstack/react-query/build/legacy';
import { ApiError, CreateTag, TagResponse } from '@/client';
import { mgmtPlaneAPIClient } from '@/client/mgmtPlane';

export type NewTagMutationConfig = {
  onSuccess?: (data: TagResponse, variables: CreateTag) => void;
  onError?: (error: ApiError, variables: CreateTag) => void;
  onSettled?: (data: TagResponse | undefined, error: ApiError | null, variables: CreateTag) => void;
};

export function getNewTagMutationOptions(tenantId: string, queryClient: QueryClient, config?: NewTagMutationConfig) {
  return {
    mutationFn: (newTag: CreateTag) => {
      return mgmtPlaneAPIClient.tags.createTag({ tenantId, requestBody: newTag });
    },
    onSuccess: async (data, variables) => {
      if (config?.onSuccess) {
        await config.onSuccess(data, variables);
      }
      queryClient.invalidateQueries({ queryKey: ['tags'] });
    },
    onError: config?.onError,
    onSettled: config?.onSettled,
  } satisfies UseMutationOptions<TagResponse, ApiError, CreateTag>;
}

export function useNewTagMutation(tenantID: string, config?: NewTagMutationConfig) {
  const queryClient = useQueryClient();
  return useMutation<TagResponse, ApiError, CreateTag>(getNewTagMutationOptions(tenantID, queryClient, config));
}
