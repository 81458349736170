/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Space as AntSpace } from 'antd';
import { SpaceSize, tokens } from '@/theme';

export type ThemeProps = {
  children: React.ReactNode;
  dir?: 'vertical' | 'horizontal';
  size?: SpaceSize;
  compact?: boolean;
  width?: string;
  minHeight?: string;
  dataTestId?: string;
};

export function Space({ children, dir, size, compact, width, minHeight, dataTestId }: ThemeProps) {
  const direction = dir || 'horizontal';
  const sizePx = tokens.size.space[size || 'xs'];

  if (compact) {
    return (
      <AntSpace.Compact data-testid={dataTestId} direction={direction}>
        {children}
      </AntSpace.Compact>
    );
  }
  return (
    <AntSpace data-testid={dataTestId} style={{ width, minHeight }} direction={direction} size={sizePx}>
      {children}
    </AntSpace>
  );
}
