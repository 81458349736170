/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { StateCreator } from 'zustand';

export enum Step {
  Welcome = 0,
  Download = 1,
  Verify = 2,
  Authenticate = 3,
  Complete = 4,
}

type StepValue = 0 | 1 | 2 | 3 | 4;
const stepNumbers: StepValue[] = [0, 1, 2, 3, 4];

export interface SetupCLISlice {
  step: StepValue;
  nextStep: () => void;
  prevStep: () => void;
}

function limitNumber(n: number): StepValue {
  const i = (stepNumbers as number[]).indexOf(n);
  if (i !== -1) {
    return stepNumbers[i];
  }
  throw new Error('Invalid step');
}

export const createSetupCLISlice: StateCreator<SetupCLISlice, [], [], SetupCLISlice> = (set) => ({
  step: 0,
  nextStep: () => set((state) => ({ step: limitNumber(state.step + 1) })),
  prevStep: () => set((state) => ({ step: limitNumber(state.step - 1) })),
});
