import { LoadingOutlined } from '@ant-design/icons';

type LoadingCountTextParams = {
  loading: boolean;
  text: string;
  count: number;
  'data-testid': string;
};

export function CountLabel({ loading, text, count, 'data-testid': dataTestId }: LoadingCountTextParams) {
  if (loading) {
    return (
      <span data-testid={dataTestId}>
        {text} <LoadingOutlined />
      </span>
    );
  }
  return (
    <span data-testid={dataTestId}>
      {text} ({count})
    </span>
  );
}
