/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import './Collapse.css';

import { Collapse as AntDCollapse } from 'antd';

export type CollapseProps = {
  items: { key: string; label: React.ReactNode; children: React.ReactNode }[];
  style?: React.CSSProperties;
};

export function Collapse({ items, style }: CollapseProps) {
  return <AntDCollapse items={items} style={{ ...style }} />;
}
