import { Alert as AntDAlert } from 'antd';
import './Alert.css';

export type AlertProps = {
  message?: string | React.ReactNode;
  description?: string | React.ReactNode;
  type?: 'success' | 'info' | 'warning' | 'error';
  showIcon?: boolean;
  dataTestId?: string;
};

export function Alert({ message, description, type, showIcon, dataTestId }: AlertProps) {
  return (
    <AntDAlert data-testid={dataTestId} message={message} description={description} type={type} showIcon={showIcon} />
  );
}
