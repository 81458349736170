/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Form, Modal } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateUser } from '@/client';
import { InviteMemberForm } from '@/components/Members/InviteMemberForm';
import { StatusText } from '@/components/StatusText';
import { useMessage } from '@/primitives/message';
import { useMgmtPlaneStateStore } from '@/state/mgmtPlaneStore';
import { useNewUserMutation } from '@/utils/hooks/useNewUserMutation';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';

export function InviteMemberModal() {
  const { t } = useMimicTranslation('members');
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState<'unconfirmed' | 'pending' | 'confirmed'>('unconfirmed');
  const [selectedRole, setSelectedRole] = useState<string>('editor');
  const tenantID = useMgmtPlaneStateStore((state) => state.selectedTenantID);
  const [form] = Form.useForm<CreateUser>();
  const [message] = useMessage();

  const closeModal = () => {
    setOpen(false);
  };

  const afterClose = () => {
    navigate(-1);
  };

  const afterSuccess = () => {
    setStatus('confirmed');
    setSelectedRole('editor');
    form.resetFields();
    setTimeout(() => {
      setStatus('unconfirmed');
    }, 2000);
  };

  const { mutate } = useNewUserMutation(tenantID!, {
    onSuccess: () => {
      afterSuccess();
    },
    onError: (_, createUserDto) => {
      message.error(
        t('feedback.userInvited', {
          user: `${createUserDto.firstName} ${createUserDto.lastName}`,
          email: `${createUserDto.email}`,
        }),
      );
    },
  });

  async function validateAndSubmit() {
    try {
      setStatus('pending');
      const userParams = await form.validateFields();
      mutate(userParams);
    } catch (error) {
      setStatus('unconfirmed');
    }
  }

  return (
    <Modal
      title={t('invite.title')}
      centered
      afterClose={afterClose}
      open={open}
      onOk={() => validateAndSubmit()}
      onCancel={closeModal}
      confirmLoading={status === 'pending'}
      okButtonProps={{ disabled: status === 'pending' }}
      cancelButtonProps={{ disabled: status === 'pending' }}
      okText={
        <StatusText
          status={status}
          text={{
            unconfirmed: t('invite.buttonTextUnconfirmed'),
            pending: t('invite.buttonTextPending'),
            confirmed: t('invite.buttonTextConfirmed'),
          }}
        />
      }
    >
      <InviteMemberForm form={form} selectedRole={selectedRole} setSelectedRole={setSelectedRole} />
    </Modal>
  );
}
