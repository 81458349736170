/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { queryOptions } from '@tanstack/react-query';
import { mgmtPlaneAPIClient, PaginationParams } from '@/client/mgmtPlane';

export function getNodeLifeCycleEvents(tenantId: string, nodeId: string, paginationParams: PaginationParams) {
  return queryOptions({
    queryKey: ['node_lifecycle_events', tenantId, nodeId, paginationParams.number, paginationParams.size],
    queryFn: () =>
      mgmtPlaneAPIClient.nodeLifecycleEvents.getLifecycleEventsByNode({
        tenantId,
        nodeId,
        page: paginationParams.number,
        pageSize: paginationParams.size,
      }),
    staleTime: 5 * 1000,
  });
}
