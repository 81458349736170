/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Segmented as AntDSegmented, SegmentedProps as AntDSegmentedProps } from 'antd';
import './Segmented.css';

export type { SegmentedValue } from 'antd/es/segmented';

export type SegmentedProps = {
  options: AntDSegmentedProps['options'];
  onChange: AntDSegmentedProps['onChange'];
  defaultValue?: AntDSegmentedProps['defaultValue'];
  value?: AntDSegmentedProps['value'];
  dataTestId?: string;
};

export function Segmented({ options, onChange, defaultValue, value, dataTestId }: SegmentedProps) {
  return (
    <AntDSegmented
      data-testid={dataTestId}
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
    />
  );
}
