/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { useQueryClient } from '@tanstack/react-query';
import { useSubscribeToEvents } from '@/utils/hooks/useSubscribeToEvents';
import { JobBanner } from './JobBanner';
import { useGetJobNotifications } from '@/utils/hooks/useGetJobNotifications';

export type JobBannersProps = {
  tenantId?: string;
};

export function JobBanners({ tenantId }: JobBannersProps) {
  const queryClient = useQueryClient();
  const { data: notificationsResponse } = useGetJobNotifications(tenantId);
  const jobs = notificationsResponse?.data;

  useSubscribeToEvents(
    ['node:operational-state-changed', 'node-config:assigned', 'job:updated', 'job:completed', 'job:timedout'],
    () => {
      queryClient.invalidateQueries({ queryKey: ['job-notifications', tenantId] });
    },
  );

  if (!tenantId || !jobs?.length) return null;

  return (
    <div className="job-banners" data-testid="job-banners">
      {jobs.map((job) => (
        <JobBanner key={job.id} job={job} />
      ))}
    </div>
  );
}
