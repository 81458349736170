/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Layout as AntDLayout } from 'antd';
import { CSSProperties } from 'react';

const style: CSSProperties = {
  height: '100vh',
};

export type LayoutProps = {
  children: React.ReactNode;
  hasSider?: boolean;
  height?: CSSProperties['height'];
};

export function Layout({ children, height, hasSider }: LayoutProps) {
  return (
    <AntDLayout hasSider={hasSider} style={{ ...style, height: height || style.height }}>
      {children}
    </AntDLayout>
  );
}
