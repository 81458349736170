import { Result } from '@/primitives/Result';
import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import Flaming404 from './Icons/Flamingo404.svg?react';

export type HTTPErrorProps = {
  httpStatus: '403' | '404' | '500';
};

type ErrorMessages = {
  [key in HTTPErrorProps['httpStatus']]: {
    title: 'forbiddenError' | 'notFoundError' | 'unexpectedError';
    subTitle: 'forbiddenErrorDescription' | 'notFoundErrorDescription' | 'unexpectedErrorDescription';
    icon?: React.ReactNode;
  };
};

const errorMessages: ErrorMessages = {
  '403': {
    title: 'forbiddenError',
    subTitle: 'forbiddenErrorDescription',
  },
  '404': {
    title: 'notFoundError',
    subTitle: 'notFoundErrorDescription',
    icon: <Flaming404 fontSize="20em" />,
  },
  '500': {
    title: 'unexpectedError',
    subTitle: 'unexpectedErrorDescription',
  },
};

export function HTTPError({ httpStatus }: HTTPErrorProps) {
  const { t } = useMimicTranslation('error');
  const { icon, title, subTitle } = errorMessages[httpStatus];
  return (
    <div data-testid={`http-error-${httpStatus}`}>
      <Result icon={icon} status={!icon ? httpStatus : undefined} title={t(title)} subTitle={t(subTitle)} />
    </div>
  );
}
