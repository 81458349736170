/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Tooltip as AntDTooltip } from 'antd';
import { TooltipPlacement, TooltipPropsWithOverlay } from 'antd/es/tooltip';

export type TooltipProps = {
  title: TooltipPropsWithOverlay['title'];
  placement?: TooltipPlacement;
  children: React.ReactNode;
};

export function Tooltip({ title, placement, children }: TooltipProps) {
  return (
    <AntDTooltip placement={placement} title={title}>
      {children}
    </AntDTooltip>
  );
}
