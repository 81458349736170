/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ReactNode } from 'react';
import './CardCollapse.css';

export function CardCollapse({ children }: { children: ReactNode }) {
  return <div className="card-collapse">{children}</div>;
}
