/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { ExclamationCircleFilled } from '@ant-design/icons';
import { tokens } from '@/theme';

export type ErrorIconParams = {
  on?: 'toast';
};

const COLOR_MAP = {
  default: tokens.color.error.error,
  toast: tokens.color.components.message.errorOnToastMessageBg,
};

export function ErrorIcon({ on }: ErrorIconParams) {
  const color = COLOR_MAP[on || 'default'];
  return <ExclamationCircleFilled style={{ color }} />;
}
