/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Timeline as AntDTimeline, TimelineProps as AntDTimelineProps } from 'antd';
import './Timeline.css';

export type TimelineProps = {
  mode?: AntDTimelineProps['mode'];
  pending?: AntDTimelineProps['pending'];
  pendingDot?: AntDTimelineProps['pendingDot'];
  reverse?: AntDTimelineProps['reverse'];
  items: AntDTimelineProps['items'];
};

export function Timeline({ mode, pending, pendingDot, reverse, items }: TimelineProps) {
  return <AntDTimeline mode={mode} pending={pending} pendingDot={pendingDot} reverse={reverse} items={items} />;
}
