import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { Interceptors } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { ActivitiesService } from './services.gen';
import { AlertsService } from './services.gen';
import { AuthService } from './services.gen';
import { CliService } from './services.gen';
import { ConfigRevisionService } from './services.gen';
import { ConfigRevisionsService } from './services.gen';
import { JobsService } from './services.gen';
import { NodeConfigsService } from './services.gen';
import { NodeCredentialsService } from './services.gen';
import { NodeLifecycleEventsService } from './services.gen';
import { NodesService } from './services.gen';
import { NotificationsService } from './services.gen';
import { ProvisioningCredentialsService } from './services.gen';
import { ReleasesService } from './services.gen';
import { SystemInfoService } from './services.gen';
import { TagsService } from './services.gen';
import { TenantReleasesService } from './services.gen';
import { TenantsService } from './services.gen';
import { UsersService } from './services.gen';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class MgmtPlaneClient {

	public readonly activities: ActivitiesService;
	public readonly alerts: AlertsService;
	public readonly auth: AuthService;
	public readonly cli: CliService;
	public readonly configRevision: ConfigRevisionService;
	public readonly configRevisions: ConfigRevisionsService;
	public readonly jobs: JobsService;
	public readonly nodeConfigs: NodeConfigsService;
	public readonly nodeCredentials: NodeCredentialsService;
	public readonly nodeLifecycleEvents: NodeLifecycleEventsService;
	public readonly nodes: NodesService;
	public readonly notifications: NotificationsService;
	public readonly provisioningCredentials: ProvisioningCredentialsService;
	public readonly releases: ReleasesService;
	public readonly systemInfo: SystemInfoService;
	public readonly tags: TagsService;
	public readonly tenantReleases: TenantReleasesService;
	public readonly tenants: TenantsService;
	public readonly users: UsersService;

	public readonly request: BaseHttpRequest;

	constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
		this.request = new HttpRequest({
			BASE: config?.BASE ?? '',
			VERSION: config?.VERSION ?? '1.0.0',
			WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
			CREDENTIALS: config?.CREDENTIALS ?? 'include',
			TOKEN: config?.TOKEN,
			USERNAME: config?.USERNAME,
			PASSWORD: config?.PASSWORD,
			HEADERS: config?.HEADERS,
			ENCODE_PATH: config?.ENCODE_PATH,
			interceptors: {
				request: config?.interceptors?.request ?? new Interceptors(),
				response: config?.interceptors?.response ?? new Interceptors(),
      },
		});

		this.activities = new ActivitiesService(this.request);
		this.alerts = new AlertsService(this.request);
		this.auth = new AuthService(this.request);
		this.cli = new CliService(this.request);
		this.configRevision = new ConfigRevisionService(this.request);
		this.configRevisions = new ConfigRevisionsService(this.request);
		this.jobs = new JobsService(this.request);
		this.nodeConfigs = new NodeConfigsService(this.request);
		this.nodeCredentials = new NodeCredentialsService(this.request);
		this.nodeLifecycleEvents = new NodeLifecycleEventsService(this.request);
		this.nodes = new NodesService(this.request);
		this.notifications = new NotificationsService(this.request);
		this.provisioningCredentials = new ProvisioningCredentialsService(this.request);
		this.releases = new ReleasesService(this.request);
		this.systemInfo = new SystemInfoService(this.request);
		this.tags = new TagsService(this.request);
		this.tenantReleases = new TenantReleasesService(this.request);
		this.tenants = new TenantsService(this.request);
		this.users = new UsersService(this.request);
	}
}
