/*
 * Copyright Mimic Networks, Inc. 2024.
 */

import { Divider as AndtDDivider } from 'antd';
import { tokens } from '@/theme';

export type DividerProps = {
  vertical?: boolean;
  dark?: boolean;
};

export function Divider({ vertical, dark }: DividerProps) {
  const type = vertical ? 'vertical' : 'horizontal';
  const color = dark ? tokens.color.border.borderDeep : undefined;
  return <AndtDDivider type={type} style={{ margin: 0, borderInlineStartColor: color }} />;
}
