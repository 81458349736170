import { useMimicTranslation } from '@/utils/translation/useMimicTranslation';
import { Container } from '@/primitives/Container';
import { Text } from '@/primitives/Text';
import { Title } from '@/primitives/Title';
import { Link } from '@/primitives/Link';
import { Button, ButtonProps } from '@/primitives/Button';
import { OsIcon } from '../Icons';
import { tokens } from '@/theme';
import { Flex } from '@/primitives/Flex';
import { OsType } from '@/client';

export type CLIDownloadParams = {
  tenantID: string;
  onDownload: (osType: OsType) => void;
};

export type BigButtonParams = {
  onClick?: ButtonProps['onClick'];
  icon?: ButtonProps['icon'];
  children?: ButtonProps['children'];
  ['data-testid']?: string;
};

function BigButton({ onClick, icon, children, 'data-testid': dataTestId }: BigButtonParams) {
  const height = tokens.size.space.xxl;
  const style: React.CSSProperties = {
    height,
    width: '100%',
  };
  return (
    <Button style={style} type="primary" icon={icon} onClick={onClick} dataTestId={dataTestId}>
      {children}
    </Button>
  );
}

export function CLIDownload({ onDownload, tenantID }: CLIDownloadParams) {
  const { t } = useMimicTranslation('cliSetup');

  function renderButton(osType: OsType) {
    return (
      <BigButton data-testid={`download-${osType}`} icon={<OsIcon name={osType} />} onClick={() => onDownload(osType)}>
        {t(`download.${osType}`)}
      </BigButton>
    );
  }

  return (
    <Container gap="xl">
      <Title level={2}>{t('download.title')}</Title>
      <Text size="xl">{t('download.subtitle')}</Text>
      <Flex gap="sm" justify="space-between" full>
        {renderButton('linux')}
        {renderButton('windows')}
        {renderButton('mac')}
      </Flex>
      <Text data-testid="cli-authentication-link">
        {t('download.after.span1')}{' '}
        <Link to={`/tenants/${tenantID}/mimic-cli/authenticate`}>{t('download.after.linkText')}</Link>.
      </Text>
    </Container>
  );
}
